const GetClient = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const {
      meta,
      data,
      paging
    } = await requests.client.get(account, id, null, query);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateClient = async (core, account, name) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.client.create(new models.Client({name: name}), account);

    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateClient = async (core, client) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.client.update(new models.Client(client));

    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteClient = async (core, client) => {
  const { requests } = core;

  try {
    const { meta } = await requests.client.delete(client);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetClient,
  CreateClient,
  UpdateClient,
  DeleteClient
}
