const GetAccount = async (core, id, query) => {
  const { requests } = core;

  try {
    const {
      data,
      meta,
      paging
    } = await requests.account.get(id, null, query);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const GetAccountByName = (core, name) => {
  return GetAccount(core, null, { name });
}

const CreateAccount = async (core, d) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      data,
      meta
    } = await requests.account.create(new models.Account(d));

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateAccount = async (core, account) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.account.update(new models.Account(account));

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteAccount = async (core, account) => {
  const {
    requests,
    models
  } = core;

  try {
    const { meta } = await requests.account.delete(new models.Account(account));

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetAccount,
  GetAccountByName,
  CreateAccount,
  UpdateAccount,
  DeleteAccount
}
