import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QueryParser } from '../../query';
import {
  registrationConstants,
  appConstants
} from '../../constants';
import {
  loading,
  register,
  alert,
  app
} from '../../actions';
import { InvitationCode } from '../../services';
import {
  Form,
  Button,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import './Registration.css';
import { withRouter } from "react-router-dom";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
    this.register = this.register.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRetypePasswordChange = this.onRetypePasswordChange.bind(this);
    this.buttonState = this.buttonState.bind(this);
    this.fieldsValidation = this.fieldsValidation.bind(this);
    this.loadCode = this.loadCode.bind(this);
  }

  componentDidMount() {
    const {
      location,
      init,
      loadingBegin
    } = this.props;
    const { pathname } = location;

    if (pathname.includes('/invitation')) {
      const { code } = QueryParser(this.props);

      if (code && code.length > 0) {
        loadingBegin('Loading Invitation');
        this.setState({ code });
        return init();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      register,
      loadingEnd,
      registerAccount,
      history,
      app
    } = this.props;
    const {
      name,
      email,
      password,
      code
    } = this.state;

    if (prevProps.app !== app
      && app.type === appConstants.INITIALIZED) {
        if (!code) {
          return registerAccount(name, email, password);
        }
        return this.loadCode(code);
    }

    if (register.type === registrationConstants.SUCCESS) {
      loadingEnd();
      return history.push('/login');
    }
  }

  async loadCode(code) {
    const {
      loadingEnd,
      onError,
      app
    } = this.props;

    try {
      const invitation = await InvitationCode(app.app, code);
      let email = '';
      const { data } = invitation;

      if (data.email) email = data.email
      loadingEnd();
      return this.setState({
        email,
        invitation
      });
    } catch (e) {
      loadingEnd();
      return onError(e.message);
    }
  }

  register(e) {
    e.preventDefault();

    const {
      password,
      passwordRetype,
      name,
      email,
      invitation,
    } = this.state;
    const {
      init,
      loadingBegin,
      onError,
      app,
      registerAccount
    } = this.props;

    if (password === passwordRetype) {
      loadingBegin('Creating Account');
      if (app.app) {
        let invite;

        if (invitation) invite = invitation.data;
        return registerAccount(name, email, password, invite);
      }
      return init();
    }
    return onError('password and re-type password must match.');
  }

  onNameChange(e) {
    e.preventDefault();

    this.setState({name: e.target.value});
  }

  onEmailChange(e) {
    e.preventDefault();

    this.setState({email: e.target.value});
  }

  onPasswordChange(e) {
    e.preventDefault();

    this.setState({password: e.target.value});
  }

  onRetypePasswordChange(e) {
    e.preventDefault();

    this.setState({passwordRetype: e.target.value});
  }

  fieldsValidation() {
    const {
      name,
      email,
      password,
      passwordRetype
    } = this.state;

    return (
      !name
      || !email
      || !password
      || !passwordRetype
      || name.length < 1
      || email.length < 1
      || !email.includes('@')
      || !email.includes('.')
      || password.length < 8
      || passwordRetype.length < 8
    );
  }

  buttonState() {
    return {
      variant:"primary",
      type: "submit",
      onClick: this.register,
      disabled: this.fieldsValidation()
    }
  }

  render() {
    const { email } = this.state;

    return (
      <Row>
        <Col md={{ span: 4, offset: 4 }}>

          <Card className="auth-card">
            <Card.Body>
              <Card.Title>Create Account</Card.Title>
              <Form>
                <Form.Group controlId="formUserName">
                  <Form.Label className="login-form-text">Username</Form.Label>
                  <Form.Control type="username" placeholder="Enter username" onChange={this.onNameChange} />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label className="login-form-text">Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={this.onEmailChange} />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label className="login-form-text">Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={this.onPasswordChange} />
                </Form.Group>

                <Form.Group controlId="formRetypePassword">
                  <Form.Label className="login-form-text">Re-type Password</Form.Label>
                  <Form.Control type="password" placeholder="Re-type Password" onChange={this.onRetypePasswordChange} />
                </Form.Group>

                <Button {...this.buttonState()}>
                  Create
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  init: app.init,
  registerAccount: register.createAccount,
  onError: alert.error,
  loadingBegin: loading.begin,
  loadingEnd: loading.end
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration));
