import { friendConstants } from '../constants';
import {
  SessionCore,
  SessionAccount,
  GetFriend,
  CreateFriend,
  UpdateFriend,
  DeleteFriend
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: friendConstants.REQUESTING }
}

const retrieved = (friend, friends) => {
  return {
    type: friendConstants.RETRIEVED,
    friend,
    friends
  }
}

const created = (friend) => {
  return {
    type: friendConstants.CREATED,
    friend
  }
}

const updated = (friend) => {
  return {
    type: friendConstants.UPDATED,
    friend
  }
}

const deleted = () => {
  return {
    type: friendConstants.DELETED
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: friendConstants.CLEARED
    });
  };
}

const Get = (id, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    if (id !== undefined) dispatch(loading.begin('Loading Friend'));
    else dispatch(loading.begin('Loading Friends'));

    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
        paging
      } = await GetFriend(core, account, id, Object.assign(query, {verbose: true}));
      let {
        friend,
        friends
      } = state.friend;

      if (Array.isArray(data)) friends = { data, paging };
      else friend = data;

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(friend, friends));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (friend) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
      } = await CreateFriend(core, account, friend);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (friend, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
      } = await UpdateFriend(core, friend, account, query);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (friend) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      await DeleteFriend(core, friend, account);

      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const friend = {
  Get,
  Create,
  Update,
  Delete,
  Clear
};
