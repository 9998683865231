import { Http } from '../utils';
import config from '../config';

class LocationManager extends Http {
  constructor() {
    const { geocode } = config;
    super({host: `${geocode.apiUrl}?key=${geocode.apiKey}`});
  }

  async getPosition() {
    try {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      return Promise.resolve(pos);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async query(query) {
    return super.get(`&q=${encodeURIComponent(query)}&language=en&pretty=1&no_annotations=1`);
  }

  async reverse(coords) {
    return super.get(`&q=${encodeURIComponent(`${coords[0]},${coords[1]}`)}&pretty=1&no_annotations=1`);
  }
}

const Location = new LocationManager();

export {
  Location
}
