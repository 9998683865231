import { combineReducers } from 'redux';

import account from './account.reducer';
import alert from './alert.reducer';
import app from './app.reducer';
import auth from './auth.reducer';
import booking from './booking.reducer';
import client from './client.reducer';
import friend from './friend.reducer';
import invitation from './invitation.reducer';
import item from './item.reducer';
import load from './load-animation.reducer';
import message from './message.reducer';
import notification from './notification.reducer';
import profile from './profile.reducer';
import recovery from './recovery.reducer';
import register from './registration.reducer';
import session from './session.reducer';
import user from './user.reducer';

const appReducer = combineReducers({
  account,
  alert,
  app,
  auth,
  booking,
  client,
  friend,
  invitation,
  item,
  load,
  message,
  notification,
  profile,
  recovery,
  register,
  session,
  user
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGGEDOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;
