import { authConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case authConstants.BEGIN:
    case authConstants.SUCCESS:
    case authConstants.FAILED:
    case authConstants.LOGGEDOUT:
      return {...state, ...action};
    case authConstants.CLEARED:
      return {};
    default:
      return state;
  }
};
