const GetNotification = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const res = await requests.alert.get(account, id, null, query);
    const {
      meta,
      data,
      paging
    } = res;

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateNotification = async (core, account, notification) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.alert.create(new models.Alert(notification), account);

    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

// const UpdateNotification = async (core, account, notification) => {
//   const {
//     models,
//     requests
//   } = core;
//
//   try {
//     const {
//       meta,
//       data
//     } = await requests.notification.update(new models.Notification(notification), account);
//
//     return Promise.resolve({
//       meta,
//       data: data.map(a => a.export())
//     });
//   } catch (e) {
//     return Promise.reject(e);
//   }
// }

const DeleteNotification = async (core, account, notification) => {
  const {
    models,
    requests
  } = core;

  try {
    const { meta } = await requests.alert.delete(new models.Alert(notification), account);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetNotification,
  CreateNotification,
  // UpdateNotification,
  DeleteNotification
}
