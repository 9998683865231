import { friendConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case friendConstants.REQUESTING:
    case friendConstants.RETRIEVED:
    case friendConstants.SELECTED:
    case friendConstants.CREATED:
    case friendConstants.UPDATED:
    case friendConstants.DELETED:
      return Object.assign({}, state, action);
    case friendConstants.CLEAR:
      return {};
    default:
      return state;
  }
};
