import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading
} from '../../actions';
import {
  AddItem,
  Search,
  Modal
} from '../../components';
import {
  Col,
  Row,
  Tabs,
  Tab
} from 'react-bootstrap';
import './ItemsPage.css';
import ItemsList from './items-list';
import BorrowedItems from './borrowed-items';
import LentItems from './lent-items';

class ItemsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'my-items',
      search: null,
      searchQuery: null
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showItemsModal = this.showItemsModal.bind(this);
  }

  componentDidMount() {
    return this.props.endLoading();
  }

  onSearchFieldChange(searchQuery) {
    this.setState({ searchQuery });
  }

  onSearchSubmit() {
    const { searchQuery } = this.state;
    this.setState({ search: searchQuery });
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  showItemsModal() {
    return this.setState({
      modal: {
        title: 'Invite New Friend',
        content: <AddItem onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  render() {
    const {
      key,
      search
    } = this.state;
    let placeholder;

    if (key === "currently-borrowed") placeholder = 'Search borrowed by me';
    else if (key === "currently-rented-out") placeholder = 'Search my items lent out';
    else placeholder = 'Search My Items';

    return (
      <Row>
        { this.showModal() }
        <Col>
          <Row className='search-bar'>
            <Col md={{ span: 6, offset: 3 }}>
              <Search placeholder={placeholder} onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                activeKey={key}
                onSelect={(k) => this.setState({ key: k, searchQuery: null, search: null })}>
                <Tab eventKey="my-items" title="My Items">
                  <ItemsList search={key === 'my-items' ? search : null} createItem={this.showItemsModal} />
                </Tab>
                <Tab eventKey="currently-borrowed" title="Borrowed by Me">
                  <BorrowedItems search={key === 'currently-borrowed' ? search : null} createItem={this.showItemsModal} />
                </Tab>
                <Tab eventKey="currently-rented-out" title="Items lent out">
                  <LentItems search={key === 'currently-rented-out' ? search : null} createItem={this.showItemsModal} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemsPage));
