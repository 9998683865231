import React from 'react';
import {
  Alert,
  AlertTitle
} from '@material-ui/lab';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { alert } from '../../actions';
import { alertConstants } from '../../constants';
import './alert.css';

const AlertComponent = props => {
  const { alert } = props;
  let heading;
  let variant;

  switch (alert.type) {
    case alertConstants.ERROR:
      heading = <AlertTitle>Error</AlertTitle>;
      variant = 'error';
      break;
    case alertConstants.INFO:
      heading = <AlertTitle>Info</AlertTitle>;
      variant = 'info';
      break;
    case alertConstants.WARNING:
      heading = <AlertTitle>Error</AlertTitle>;
      variant = 'warning';
      break;
    default:
      heading = <AlertTitle>Success</AlertTitle>;
      variant = 'success';
  }

  if (alert.message) {
    setTimeout(() => {
      props.clear();
    }, 5000);
  }

  return (
    <Collapse in={alert.message !== undefined}>
      <Alert className="alert-component" severity={variant} onClose={ () => props.clear() } dismissible>
        {heading}
        {alert.message}
      </Alert>
    </Collapse>
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  clear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
