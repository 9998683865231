import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading,
  item
} from '../../../actions';
import { itemConstants } from '../../../constants';
import {
  AddItem,
  DeleteItem,
  ItemsViewer,
  Modal
} from '../../../components';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import './ItemsList.css';

class ItemsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {
        data: [],
        paging: {
          limit: 9,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };
    this.renderList = this.renderList.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.showItemModal = this.showItemModal.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.showModal = this.showModal.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
    this.selectedEdit = this.selectedEdit.bind(this);
    this.selectedDelete = this.selectedDelete.bind(this);
  }

  componentDidMount() {
    return this.refreshList();
  }

  refreshList(search) {
    const {
      getItems,
      session
    } = this.props;
    const { items } = this.state;
    const { paging } = items;
    const { account } = session;
    const {
      limit,
      offset
    } = paging;
    const query = {
      limit,
      offset
    };

    if (search) query.search = search;
    return getItems(query, null, account.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      endLoading,
      search
    } = this.props;

    if (prevProps.search !== search) {
      return this.refreshList(search);
    }

    if (prevProps.item !== item) {
      if (item.type === itemConstants.RETRIEVED) {
        const { items } = item;

        this.setState({ items });
        return endLoading();
      }

      if (item.type === itemConstants.DELETED) {
        this.setState({ modal: undefined });
        return this.refreshList();
      }
    }
  }

  onRowsPerPageChange(row) {
    this.props.getItems({
      offset: 0,
      limit: row
    });
  }

  showItemModal(e) {
    e.preventDefault();

    return this.setState({
      modal: {
        title: 'Add New Item',
        content: <AddItem onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  onPageChange(page) {
    const { items } = this.state;
    const { paging } = items;
    const { limit } = paging;

    this.props.getItems({
      offset: limit * page,
      limit
    });
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  selectedItem(i) {
    const { history } = this.props;

    return history.push(`/item/${i.id}`);
  }

  selectedEdit(item) {
    console.log(item);
  }

  selectedDelete(item) {
    return this.setState({
      modal: {
        title: `Delete ${item.name}`,
        content: <DeleteItem itemToDelete={item} onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  renderList() {
    const { items } = this.state;

    if (items && items.data.length > 0) {
      const { data } = items;

      return <ItemsViewer
        items={data}
        onItemSelect={this.selectedItem}
        onEditSelect={this.selectedEdit}
        onDeleteSelect={this.selectedDelete}
        owner={true} />
    }
    return (
      <Row className='empty-message'>
        <Col>
          <h3>You currently have no items to share</h3>
          <h4>Click below to create an item</h4>
          <Button onClick={this.showItemModal}>Create Item</Button>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        { this.showModal() }
        <Col>
          { this.renderList() }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getItems: item.Get,
  updateItem: item.Update,
  deleteItem: item.Delete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemsList));
