import config from '../config';
const { Partizlice } = window;

const Login = (client, email, password) => {
  return Partizlice.authenticate({
    host:`${config.host}/v${config.apiVersion}`,
    username: email,
    password: password,
    clientId: client
  });
}

const SSO = (clientId, token) => {
  return Partizlice.sso({
    host: `${config.host}/v${config.apiVersion}`,
    clientId: clientId,
    token: token
  });
}

const Refresh = (token) => {
  return Partizlice.refresh({
    host: `${config.host}/v${config.apiVersion}`,
    token
  });
}

const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
}

export {
  Login,
  SSO,
  Refresh,
  Logout
}
