const ActivationEmail = (core, user) => {
  const {
    requests,
    models
  } = core;

  return requests.activation.sendActivationEmail(new models.User(user));
}

const ActivationSms = (core, user) => {
  const {
    requests,
    models
  } = core;

  return requests.activation.sendActivationSms(new models.User(user));
}

const ActivateAccount = (core, code) => {
  const { requests } = core;

  return requests.activation.activateAccount(code);
}

export {
  ActivationEmail,
  ActivationSms,
  ActivateAccount
}
