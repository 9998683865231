import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  alert,
  message,
  loading
} from '../../actions';
import { messageConstants } from '../../constants';
import {
  Button,
  Col,
  Form,
  Row
} from 'react-bootstrap';
import {
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import './Messenger.css';

class Messenger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageToSend: '',
      messages: [],
      contacts: []
    };
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.submit = this.submit.bind(this);
    this.enterKeyDown = this.enterKeyDown.bind(this);
    this.chatFeed = this.chatFeed.bind(this);
  }

  componentDidMount() {
    const {
      messengerId,
      getMessages
    } = this.props;

    return getMessages(messengerId, { verbose: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { message } = this.props;
    const chat = document.getElementById('chat-history');

    chat.scrollTop = chat.scrollHeight;
    if (prevProps.message !== message) {
      if (message.type === messageConstants.RETRIEVED
        || message.type === messageConstants.UPDATED) {
        const {
          contacts,
          messages
        } = message.message;

        return this.setState({
          message: message.message,
          contacts,
          messages
        });
      }
    }
  }

  handleMessageSubmit(e){
    e.preventDefault();
    this.submit();
  }

  submit() {
    const {
      session,
      updateMessages
    } = this.props;
    const {
      messageToSend,
      message,
      messages
    } = this.state;

    messages.push({
      created_at: Date.now(),
      from: session.account.id,
      message: messageToSend
    });
    this.setState({ messageToSend: ''});
    const newMessage = Object.assign(message, { messages });

    delete newMessage.contacts;
    return updateMessages(newMessage, { verbose: true });
  }

  enterKeyDown(e) {
    const { messageToSend } = this.state;

    if (e.key.toLowerCase() === 'enter') {
      e.preventDefault();
      if (messageToSend.length > 0) this.submit();
    }
  }

  chatFeed() {
    const { session } = this.props;
    const {
      message,
      messages,
      contacts
    } = this.state;

    if (message) {
      return messages.map((m, i) => {
        const sender = m.from === session.account.id ? session : contacts[m.from];

        return (
          <div key={i}>
            { i > 0 ? <Divider variant="inset" component="li" /> : null }
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar className='notification-image' alt={sender.account.name}  src={sender.user.avatar ? sender.user.avatar.url : null}>
                  { sender.account.name.substring(0,2).toUpperCase() }
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={sender.account.name}
                secondary={
                  <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    { m.message }
                  </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </div>
        );
      });
    }
    return <CircularProgress />;
  }

  render() {
    const { messageToSend } = this.state;

    return (
      <div>
        <List className='chat-history' id='chat-history'>
          { this.chatFeed() }
        </List>
      </div>
    );
  }
}

// <br />
// <Form>
//   <Row>
//     <Col xs="10">
//       <Form.Group>
//         <Form.Control
//         type="text"
//         as="textarea"
//         rows={3}
//         value={messageToSend}
//         placeholder="Message..."
//         onChange={e => this.setState({ messageToSend: e.target.value })}
//         onKeyDown={this.enterKeyDown} />
//       </Form.Group>
//     </Col>
//     <Col xs="2">
//       <Button
//         onClick={this.handleMessageSubmit}
//         disabled={messageToSend.length < 1}
//         className='item-submit-button'>
//         Send
//       </Button>
//     </Col>
//   </Row>
// </Form>

Messenger.propTypes = {
  messengerId: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  onError: alert.error,
  beginLoading: loading.begin,
  endLoading: loading.end,
  getMessages: message.Get,
  updateMessages: message.Update
};

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
