import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form
} from 'react-bootstrap';
import './AddFriendForm.css';

class AddFriendForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      message: props.message
    };
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
    this.onEmailFieldChange = this.onEmailFieldChange.bind(this);
    this.onMessageFieldChange = this.onMessageFieldChange.bind(this);
  }

  submitButtonClicked(e) {
    e.preventDefault();
    const {
      email,
      message
    } = this.state;

    if (email.length > 0) {
      return this.props.onSubmit({
        email,
        message
      });
    }
  }

  onEmailFieldChange(e) {
    e.preventDefault();
    let { email } = this.state;

    email = e.target.value;
    this.setState({email});
  }

  onMessageFieldChange(e) {
    e.preventDefault();
    let { message } = this.state;

    message = e.target.value;
    this.setState({message});
  }

  render() {
    const {
      email,
      message
    } = this.state;
    return (
      <Form>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
          value={email}
          type="text"
          placeholder="email"
          onChange={this.onEmailFieldChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} value={message} onChange={this.onMessageFieldChange}/>
        </Form.Group>

        <Button
          onClick={this.submitButtonClicked}
          disabled={email.length < 1}
          className='invite-submit-button'>
          Send Invitation
        </Button>
      </Form>
    );
  }
}

AddFriendForm.defaultProps = {
  email: '',
  message: ''
};

AddFriendForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AddFriendForm;
