const GetItem = async (core, query, id, account) => {
  const { requests } = core;

  try {
    const {
      meta,
      data,
      paging
    } = await requests.item.get(query, id, account, null);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateItem = async (core, account, item) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.item.create(new models.Item(item), account);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateItem = async (core, account, item) => {
  const {
    models,
    requests
  } = core;

  try {
    console.log(core);
    console.log(requests);
    const {
      meta,
      data
    } = await requests.item.update(new models.Item(item), account);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteItem = async (core, item) => {
  const {
    models,
    requests
  } = core;

  try {
    const { account } = item.owner;
    const { meta } = await requests.item.delete(new models.Item(item), account.id);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetItem,
  CreateItem,
  UpdateItem,
  DeleteItem
}
