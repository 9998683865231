export const accountConstants = {
  REQUESTING: 'ACCOUNT_REQUEST_BEGIN',
  SUCCESS: 'SESSION_ACCOUNT_SUCCESS',
  RETRIEVED: 'ACCOUNT_GET_SUCCESS',
  CREATED: 'ACCOUNT_CREATE_SUCCESS',
  UPDATED: 'ACCOUNT_UPDATE_SUCCESS',
  DELETED: 'ACCOUNT_DELETE_SUCCESS',
  CLEAR: 'ACCOUNT_REQUEST_CLEARED',
  FAILED: 'ACCOUNT_REQUEST_FAILURE'
}
