const GetFriend = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const res = await requests.friend.get(account.id, id, {}, query);
    console.log(res);
    const {
      meta,
      data,
      paging
    } = res;

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateFriend = async (core, account, friend) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.friend.create(new models.Friend(friend), account.id);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateFriend = async (core, friend, account) => {
  const {
    models,
    requests
  } = core;

  if (account === undefined) {
    return Promise.reject(new Error('account is required.'));
  }

  try {
    const {
      meta,
      data
    } = await requests.friend.update(new models.Friend(friend), account.id);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteFriend = async (core, friend, account) => {
  const { requests } = core;

  if (account === undefined) {
    return Promise.reject(new Error('account is required.'));
  }

  try {
    const { meta } = await requests.friend.delete(friend.id, account.id);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetFriend,
  CreateFriend,
  UpdateFriend,
  DeleteFriend
}
