import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form
} from 'react-bootstrap';
import './DeleteForm.css';

class DeleteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: ''
    };
    this.deleteButtonClicked = this.deleteButtonClicked.bind(this);
    this.onNameFieldChange = this.onNameFieldChange.bind(this);
  }

  deleteButtonClicked(e) {
    e.preventDefault();
    const {
      data,
      onDelete,
    } = this.props;

    return onDelete(data);
  }

  onNameFieldChange(e) {
    e.preventDefault();

    this.setState({confirmation: e.target.value});
  }

  render() {
    const {
      data,
      confirmationString
    } = this.props;
    if (data) {
      const message = `Type '${confirmationString.toLowerCase()}' to confirm deletion and click continue to proceed. This process is not reversable.`;

      return (
        <Form>
          <Form.Group>
            <Form.Label>{message}</Form.Label>
            <Form.Control
            type="text"
            placeholder={confirmationString.toLowerCase()}
            onChange={this.onNameFieldChange} />
          </Form.Group>

          <Button
            variant="danger"
            onClick={this.deleteButtonClicked}
            disabled={this.state.confirmation.toLowerCase() !== confirmationString.toLowerCase()}
            className='edit-site-submit-button'>
            Continue
          </Button>
        </Form>
      );
    }
    this.props.onError('Either data is null or data object has no name parameter.');
    return null;
  }
}

DeleteForm.propTypes = {
  data: PropTypes.object.isRequired,
  confirmationString: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default DeleteForm;
