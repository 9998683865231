import { notificationConstants } from '../constants';
import {
  SessionCore,
  SessionAccount,
  GetNotification,
  CreateNotification,
  // UpdateNotification,
  DeleteNotification
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: notificationConstants.REQUESTING }
}

const retrieved = (notification, notifications) => {
  return {
    type: notificationConstants.RETRIEVED,
    notification,
    notifications
  }
}

const created = (notification) => {
  return {
    type: notificationConstants.CREATED,
    notification
  }
}

// const updated = (notification) => {
//   return {
//     type: notificationConstants.UPDATED,
//     notification
//   }
// }

const deleted = (notifications) => {
  return {
    type: notificationConstants.DELETED,
    notifications
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: notificationConstants.CLEARED
    });
  };
}

const Get = (id, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
        paging
      } = await GetNotification(core, account.id, id, Object.assign({
        all: true,
        verbose: true
      }, query));
      let {
        notification,
        notifications
      } = state.notification;

      if (Array.isArray(data)) notifications = { data, paging };
      else notification = data;
      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(notification, notifications));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (notification) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateNotification(core, account, notification);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

// const Update = (notification, query) => {
//   return async (dispatch, getState) => {
//     dispatch(requesting());
//     try {
//       const state = getState();
//       const core = await SessionCore(state);
//       const account = await SessionAccount(state);
//       const {
//         meta,
//         data
//       } = await UpdateNotification(core, notification, account, query);
//
//       if (meta.message) dispatch(alert.success(meta.message));
//       return dispatch(updated(data));
//     } catch (e) {
//       dispatch(loading.end());
//       return dispatch(alert.error(e.message));
//     }
//   }
// }

const Delete = (notification) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      let { notifications } = state.notification;

      await DeleteNotification(core, account.id, notification);
      const index = notifications.data.indexOf(notification);

      notifications.data.splice(index, 1);

      return dispatch(deleted(notifications));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const notification = {
  Get,
  Create,
  // Update,
  Delete,
  Clear
};
