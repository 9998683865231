import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './PageNav.css';

class PageNavComponent extends Component {
  constructor(props) {
    super(props);
    const {
      activeKey,
      options
    } = props;

    this.state = { activeKey: activeKey !== undefined ? activeKey : 0 };
    this.renderOptions = this.renderOptions.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    this.props.onOptionSelect(eventKey);
    return this.setState({activeKey: eventKey});
  };

  renderOptions() {
    return this.props.options.map((o, i) => {
      const { disabled } = o;
      return (
        <Nav.Item key={i}>
          <Nav.Link eventKey={i} disabled={ disabled ? disabled : false }>
            {o.name}
          </Nav.Link>
        </Nav.Item>
      );
    });
  }

  render() {
    const {
      activeKey
    } = this.state;

    return (
      <Nav fill variant="tabs" activeKey={activeKey} onSelect={this.handleSelect}>
        { this.renderOptions() }
      </Nav>
    );
  }
}

PageNavComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      disabled: PropTypes.boolean
    })
  ).isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  activeKey: PropTypes.string
};

export default PageNavComponent;
