// const authHost = process.env.NODE_ENV === 'development' ? 'http://localhost:8001' : 'https://at1059xc4k.execute-api.us-east-1.amazonaws.com/dev';
const authHost = 'https://9s04c1nsg4.execute-api.us-east-1.amazonaws.com/dev';

const config = Object.freeze({
  host: authHost,
  name: 'partizlice',
  media_bucket: 'partizlice-media',
  apiVersion: 1,
  appId: process.env.NODE_ENV === 'development' ? '6096af12a6ff600009d1dbb9' : '60c18251faf462000950566c',
  geocode: {
    apiUrl: 'https://api.opencagedata.com/geocode/v1/json',
    apiKey: '6a18080b458a4c088f470e57984e8e3b',
    formatter: null
  }
});

export default config;
