import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading
} from '../../actions';
import {
  AddItem,
  Search,
  Modal
} from '../../components';
import {
  Col,
  Row,
  Tabs,
  Tab
} from 'react-bootstrap';
import './BookingsPage.css';
import PendingRequests from './pending-requests';
import PendingBookings from './pending-bookings';

class BookingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'pending-bookings',
      search: null,
      searchQuery: null
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showItemsModal = this.showItemsModal.bind(this);
  }

  componentDidMount() {
    return this.props.endLoading();
  }

  onSearchFieldChange(searchQuery) {
    this.setState({ searchQuery });
  }

  onSearchSubmit() {
    const { searchQuery } = this.state;
    this.setState({ search: searchQuery });
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  showItemsModal() {
    return this.setState({
      modal: {
        title: 'Invite New Friend',
        content: <AddItem onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  render() {
    const {
      key,
      search
    } = this.state;
    let placeholder;

    if (key === "pending-requests") placeholder = 'Search pending requests for my items';
    else placeholder = 'Search my pending booking requests';

    return (
      <Row>
        { this.showModal() }
        <Col>
          <Row className='search-bar'>
            <Col md={{ span: 6, offset: 3 }}>
              <Search placeholder={placeholder} onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                activeKey={key}
                onSelect={(k) => this.setState({ key: k, searchQuery: null, search: null })}>
                <Tab eventKey="pending-bookings" title="Pending requests for my items">
                  <PendingBookings search={key === 'pending-bookings' ? search : null} createItem={this.showItemsModal} />
                </Tab>
                <Tab eventKey="pending-requests" title="My pending booking requests">
                  <PendingRequests search={key === 'pending-requests' ? search : null} createItem={this.showItemsModal} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookingsPage));
