const GetMessage = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const {
      meta,
      data,
      paging
    } = await requests.message.get(query, id, account, null);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateMessage = async (core, account, message) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.message.create(new models.Message(message), account);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateMessage = async (core, account, message, query) => {
  const {
    models,
    requests
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.message.update(new models.Message(message), account, null, query);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteMessage = async (core, account, message) => {
  const {
    models,
    requests
  } = core;

  try {
    const { meta } = await requests.message.delete(new models.Message(message), account);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetMessage,
  CreateMessage,
  UpdateMessage,
  DeleteMessage
}
