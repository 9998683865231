import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './ItemsViewer.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 1200,
    height: 900,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    textAlign: 'left'
  },
  icon: {
    color: 'white',
  },
}));


const ItemsViewer = props => {
  const classes = useStyles();
  const {
    items,
    owner
  } = props;
  const [width, setWidth] = React.useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  window.addEventListener('resize', updateDimensions);

  const handleClick = (e, item) => {
    e.preventDefault();

    props.onItemSelect(item);
  }

  const handleEditClicked = (e, i) => {
    e.preventDefault();

    props.onEditSelect(i);
  }

  const handleDeleteClicked = (e, i) => {
    e.preventDefault();

    props.onDeleteSelect(i);
  }

  const generateCard = (item, i) => {
    const actionIcons = [
      {
        label: `edit ${item.name}`,
        icon: <EditIcon />,
        method: handleEditClicked
      },
      {
        label: `delete ${item.name}`,
        icon: <DeleteIcon />,
        method: handleDeleteClicked
      }
    ];

    let image = (
      <div className='item-placeholder' onClick={e => handleClick(e, item)}>
        <h1>{ item.name }</h1>
      </div>
    );

    if (item.media.length > 0 ) {
      image = <img src={item.media[0].url} alt={item.name} onClick={e => handleClick(e, item)} />;
    }

    return (
      <GridListTile className='item-cell' key={i}>
        { image }
        <GridListTileBar
          title={item.name}
          titlePosition="top"
          actionIcon={owner ? actionIcons.map((action, j) => {
            return (
              <IconButton key={j} aria-label={action.label} className={classes.icon} onClick={e => action.method(e, item)}>
                {action.icon}
              </IconButton>
            )
          }) : null}
          actionPosition="left"
          className={classes.titleBar}
        />
      </GridListTile>
    );
  }
  let cellHeight = 410;
  let cellCols = 3;
  if (width <= 1240) cellHeight = 350;
  if (width <= 930) cellHeight = 300;
  if (width <= 800) cellCols = 2;
  if (width <= 650) cellHeight = 250;
  if (width <= 520) {
    cellHeight = 450;
    cellCols = 1;
  }

  return (
    <div className={classes.root}>
      <GridList cellHeight={cellHeight} spacing={1} className={classes.gridList} cols={cellCols}>
        {items.map((item, i) => generateCard(item, i))}
      </GridList>
    </div>
  );
}

ItemsViewer.defaultProps = {
  items: [],
  owner: false
};

ItemsViewer.propTypes = {
  onItemSelect: PropTypes.func.isRequired,
  onDeleteSelect: PropTypes.func.isRequired,
  onEditSelect: PropTypes.func.isRequired,
  owner: PropTypes.bool,
  items: PropTypes.array
};

export default ItemsViewer;
