import { recoveryConstants } from '../constants';
import {
  App,
  RecoveryEmail,
  ResetPassword
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

/**
 * STATES
 */
const code = (code) => {
  return dispatch => {
    return dispatch({
      type: recoveryConstants.CODE,
      code
    });
  }
}

const begin = () => {
  return { type: recoveryConstants.BEGIN }
}

const success = () => {
  return { type: recoveryConstants.SUCCESS }
}
/**
 * METHODS
 */

const email = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading.begin('Sending recovery email...'));
      const app = await App(getState());

      if (!app) {
        return dispatch(alert.error('App is not loaded'));
      }
      const res = await RecoveryEmail(app, email);

      dispatch(loading.end());
      dispatch(alert.success(res.message));
      return dispatch(begin());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const reset = (password) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading.begin('Resetting password...'));
      const state = getState();
      const app = await App(state);
      const { code } = state.recovery;

      if (!app) {
        dispatch(loading.end());
        return dispatch(alert.error('App is not loaded'));
      }

      if (!code) {
        dispatch(loading.end());
        return dispatch(alert.error('Code is invalid'));
      }

      const res = await ResetPassword(app, code, password);

      dispatch(loading.end());
      dispatch(alert.success(res.message));
      return dispatch(success());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const recovery = {
  code,
  email,
  reset
};
