import React, { Component } from 'react';
import { connect } from 'react-redux';
import {} from '../../actions';
import {
  Row,
  Col
} from 'react-bootstrap';
import './Billing.css';

class Billing extends Component {
  render() {
    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1>Billing</h1>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
