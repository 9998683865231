'use strict';

import Canvas from './canvas';

const resizeImage = (f, type, name, resize) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const c = new Canvas(resize);
      const ctx = c.getContext('2d');
      ctx.drawImage(img, 0, 0, resize.width, resize.height);

      const data = c.toDataURL(type, 0.7);

      return resolve(data);
    }

    img.onerror = e => {
      return reject('resizeImage error');
    };

    img.src = URL.createObjectURL(f);
  });
}

export default {
  resizeImage
}
