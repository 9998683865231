import React, { Component } from 'react';
import { connect } from 'react-redux';
import { recoveryConstants } from '../../constants';
import { recovery } from '../../actions';
import {
  Button,
  Card,
  Col,
  Row,
  Form
} from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import './Recovery.css';

class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.buttonState = this.buttonState.bind(this);
    this.fieldsValidation = this.fieldsValidation.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.recovery.type === recoveryConstants.BEGIN) {
      return this.props.history.push('/');
    }
  }

  onEmailChange(e) {
    e.preventDefault();

    this.setState({email: e.target.value});
  }

  submit(e) {
    e.preventDefault();
    const { email } = this.state;

    if (email && email.length > 0) {
      return this.props.recover(email);
    }
  }

  fieldsValidation() {
    const { email } = this.state;

    return (!email || email.length === 0 || !email.includes('@') || !email.includes('.'));
  }

  buttonState() {
    return {
      variant:"primary",
      type: "submit",
      onClick: this.submit,
      disabled: this.fieldsValidation()
    }
  }

  render() {
    return (
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <Card className="auth-card">
            <Card.Body>
              <Card.Title>Account Recovery</Card.Title>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="login-form-text">Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={this.onEmailChange} />
                </Form.Group>
                <Button {...this.buttonState()}>
                  Recover
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  recover: recovery.email
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Recovery));
