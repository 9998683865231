import { registrationConstants } from '../constants';
import {
  App,
  CreateFriend,
  DeleteInvitation,
  Register,
  RollbackRegistration
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';


const registrationBegin = () => {
  return { type: registrationConstants.BEGIN }
}

const registrationComplete = () => {
  return { type: registrationConstants.SUCCESS }
}

const registrationFailed = () => {
  return { type: registrationConstants.FAILED }
}

const clearRegistration = () => {
  return async dispatch => {
    return dispatch({ type: registrationConstants.CLEARED });
  };
}

/**
 * Methods
 */
const createAccount = (name, email, password, invitation) => {
  return async (dispatch, getState) => {
    if (name && email && password) {
      dispatch(alert.clear());
      dispatch(registrationBegin());

      let newAccount;
      let newUser;
      try {
        const app = await App(getState());
        let {
          message,
          account,
          user
        } = await Register(app, name, email, password);

        newAccount = account;
        newUser = user;

        if (invitation) {
          const { inviter } = invitation;
          dispatch(loading.begin('Creating Friendship Connection'));
          await CreateFriend(app, inviter.account, { account: account.id });
          await DeleteInvitation(app, invitation);
        }

        dispatch(alert.success(message));
        return dispatch(registrationComplete());
      } catch (e) {
        if (newAccount || newUser) {
          const app = await App(getState());

          return RollbackRegistration(app, newAccount, newUser, e)
            .catch(e => {
              dispatch(loading.end());
              dispatch(alert.error(e.message));
              return dispatch(registrationFailed());
            });
        }
        dispatch(loading.end());
        dispatch(alert.error(e.message));
        return dispatch(registrationFailed());
      }
    } else {
      return dispatch(alert.error('Username, e-mail and password are required'));
    }
  }
}

export const register = {
  createAccount,
  clearRegistration
};
