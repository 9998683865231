import React, { Component } from 'react';
import {
  Circle,
  Map,
  TileLayer,
  Marker,
  Popup
} from 'react-leaflet';
import PropTypes from 'prop-types';
import './Map.css';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

class MapComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coordinates: props.coordinates,
      bounds: props.bounds,
      zoom: props.zoom,
      style: props.style ? props.style : {
        height: 400
      },
      markers: props.markers,
      circles: props.circles
    };
    this.addMarker = this.addMarker.bind(this);
    this.addCircles = this.addCircles.bind(this);
  }

  componentDidMount() {
    window.dispatchEvent(new Event('resize'));
  }

  componentDidUpdate(prevProps, prevState) {
    const newState = {};
    const {
      coordinates,
      bounds,
      markers
    } = this.props;

    if (coordinates !== prevState.coordinates) {
      newState.coordinates = coordinates;
    }
    if (markers !== prevState.markers) {
      newState.markers = markers;
    }
    if (bounds !== prevState.bounds) {
      newState.bounds = bounds;
      newState.zoom = null;
    }
    if (Object.keys(newState).length > 0) this.setState(newState);
  }

  addMarker() {
    const { markers } = this.state;

    return markers.map((m, i) => <Marker key={i} {...m} />);
  }

  addCircles() {
    const { circles } = this.state;

    return circles.map((r, i) => <Circle key={i} {...r} />);
  }

  render() {
    const { onClick } = this.props;
    const {
      coordinates,
      bounds,
      zoom,
      style
    } = this.state;

    return (
      <Map
      onClick={onClick}
      center={coordinates}
      bounds={bounds}
      zoom={zoom}
      style={style}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        { this.addMarker() }
        { this.addCircles() }
      </Map>
    );
  }
}

MapComponent.defaultProps = {
  markers: [],
  circles: [],
  coordinates: [51.505, -0.09],
  zoom: 200,
};

MapComponent.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.arrayOf(PropTypes.number).isRequired,
    onClick: PropTypes.func
  })),
  circles: PropTypes.arrayOf(PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    pathOptions: PropTypes.shape({
      fillColor: PropTypes.string,
      color: PropTypes.string
    }),
    radius: PropTypes.number.isRequired,
    onClick: PropTypes.func
  })),
  onClick: PropTypes.func
};

export default MapComponent;
