import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading,
  item
} from '../../../actions';
import { itemConstants } from '../../../constants';
import {
  AddItem,
  Modal
} from '../../../components';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import {
  TablePagination,
  TableContainer,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import './LentItems.css';

class LentItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {
        data: [],
        paging: {
          limit: 9,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };
    this.renderList = this.renderList.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.deleteItems = this.deleteItems.bind(this);
    this.showItemModal = this.showItemModal.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.showModal = this.showModal.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
  }

  componentDidMount() {
    return this.refreshList();
  }

  refreshList(search) {
    const {
      getItems,
      session
    } = this.props;
    const { items } = this.state;
    const { paging } = items;
    const { account } = session;
    const {
      limit,
      offset
    } = paging;
    const query = {
      limit,
      offset
    };

    if (search) query.search = search;
    return getItems(query, null, account.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      endLoading,
      search
    } = this.props;

    if (prevProps.search !== search) {
      return this.refreshList(search);
    }

    if (prevProps.item !== item) {
      if (item.type === itemConstants.RETRIEVED) {
        const { items } = item;

        this.setState({ items });
        return endLoading();
      }

      if (item.type === itemConstants.DELETED) {
        return this.refreshList();
      }
    }
  }

  onRowsPerPageChange(row) {
    this.props.getItems({
      offset: 0,
      limit: row
    });
  }

  showItemModal(e) {
    e.preventDefault();

    return this.setState({
      modal: {
        title: 'Add New Item',
        content: <AddItem onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  onPageChange(page) {
    const { items } = this.state;
    const { paging } = items;
    const { limit } = paging;

    this.props.getItems({
      offset: limit * page,
      limit
    });
  }

  deleteItems(items) {
    items.forEach((invite, i) => this.props.deleteItems(invite));
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  selectedItem(item) {
    console.log(item);
  }

  renderList() {
    const { friends } = this.state;

    if (friends && friends.data.length > 0) {
      let {
        data,
        paging
      } = friends;

      if (paging) {
        return (
          <Paper>
            <TableContainer>
              <List>
              {
                data = data.map((d, i) => {
                  const {
                    account,
                    user
                  } = d;
                  const { created_at } = d.record_history;
                  const diffInDays = Math.ceil((new Date().getTime() - new Date(created_at).getTime()) / (1000 * 3600 * 24));
                  const daysAgo = diffInDays > 0 ? `Connected ${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago` : '';

                  return (
                    <ListItem>
                      <ListItemAvatar onClick={e => this.navigateToProfile(e, account.name)}>
                        <Avatar src={user.avatar ? user.avatar.url : null}>{ account.name.substring(0,2).toUpperCase() }</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className='friend-text'
                        primary={account.name}
                        secondary={daysAgo}
                      />
                      <ListItemSecondaryAction>
                        <Button variant="danger" onClick={e => this.showDeleteFriendModal(e, d)}>Remove</Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
              }
            </List>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
          />
        </Paper>
       )
      }
    }
    return (
      <Row className='empty-message'>
        <Col>
          <h3>You currently have no items lent out.</h3>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        { this.showModal() }
        <Col>
          { this.renderList() }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getItems: item.Get,
  updateItem: item.Update,
  deleteItem: item.Delete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LentItems));
