import { itemConstants } from '../constants';
import {
  App,
  SessionCore,
  SessionAccount,
  GetItem,
  CreateItem,
  UpdateItem,
  DeleteItem
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: itemConstants.REQUESTING }
}

const retrieved = (item, items) => {
  return {
    type: itemConstants.RETRIEVED,
    item,
    items
  }
}

const created = (item) => {
  return {
    type: itemConstants.CREATED,
    item
  }
}

const updated = (item) => {
  return {
    type: itemConstants.UPDATED,
    item
  }
}

const deleted = () => {
  return {
    type: itemConstants.DELETED
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: itemConstants.CLEARED
    });
  };
}

const Get = (query, id, account) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      let core = SessionCore(state);

      query = Object.assign({ verbose: true }, query);
      if (!core) core = await App(getState());
      const {
        meta,
        data,
        paging
      } = await GetItem(core, query, id, account);
      let {
        item,
        items
      } = state.item;

      if (Array.isArray(data)) items = { data, paging };
      else item = data;

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(item, items));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (item) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateItem(core, account.id, item);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (item) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await UpdateItem(core, account.id, item);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (item) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const { meta } = await DeleteItem(core, item);

      if (meta && meta.message) dispatch(alert.success(meta.message));
      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const item = {
  Get,
  Create,
  Update,
  Delete,
  Clear
};
