import { messageConstants } from '../constants';
import {
  SessionCore,
  SessionAccount,
  GetMessage,
  CreateMessage,
  UpdateMessage,
  DeleteMessage
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: messageConstants.REQUESTING }
}

const retrieved = (message, messages) => {
  return {
    type: messageConstants.RETRIEVED,
    message,
    messages
  }
}

const created = (message) => {
  return {
    type: messageConstants.CREATED,
    message
  }
}

const updated = (message) => {
  return {
    type: messageConstants.UPDATED,
    message
  }
}

const deleted = () => {
  return {
    type: messageConstants.DELETED
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: messageConstants.CLEARED
    });
  };
}

const Get = (id, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
        paging
      } = await GetMessage(core, account.id, id, query);
      let {
        message,
        messages
      } = state.message;

      if (Array.isArray(data)) messages = { data, paging };
      else message = data;
      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(message, messages));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (message) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateMessage(core, account, message);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (message, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const { data } = await UpdateMessage(core, account.id, message, query);

      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (message) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      await DeleteMessage(core, account, message);

      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const message = {
  Get,
  Create,
  Update,
  Delete,
  Clear
};
