import { sessionConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case sessionConstants.CORE:
    case sessionConstants.ACCOUNT:
    case sessionConstants.USER:
    case sessionConstants.LOCATION:
      return {...state, ...action};
    case sessionConstants.END:
      return {};
    default:
      return state;
  }
};
