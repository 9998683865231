const SessionCore = (state) => {
  if (state && state.session.core) {
    return state.session.core;
  }
}

const SessionAccount = async (state, core) => {
  if (state) {
    const { account } = state.session;

    if (account) {
      return Promise.resolve(account);
    }

    if (!core) {
      core = SessionCore(state);
    }
  }

  if (core) {
    const { requests } = core;

    try {
      const { data } = await requests.default.account();

      return Promise.resolve(data.export());
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject('No core found.');
}

const SessionUser = async (state, core) => {
  if (state) {
    const { user } = state.session;

    if (user) {
      return Promise.resolve(user);
    }

    if (!core) {
      core = SessionCore(state);
    }
  }

  if (core) {
    const { requests } = core;

    try {
      const { data } = await requests.default.requester();

      return Promise.resolve(data.export());
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject('No core found.');
}

export {
  SessionCore,
  SessionUser,
  SessionAccount
}
