import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col
} from 'react-bootstrap';
import './Splash.css';
import logo from './logo.svg';

class Splash extends Component {
  render() {
    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
