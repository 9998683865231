import { bookingConstants } from '../constants';
import {
  SessionCore,
  SessionAccount,
  GetBooking,
  CreateBooking,
  UpdateBooking,
  DeleteBooking
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: bookingConstants.REQUESTING }
}

const retrieved = (booking, bookings, requests) => {
  return {
    type: bookingConstants.RETRIEVED,
    booking,
    bookings,
    requests
  }
}

const created = (booking) => {
  return {
    type: bookingConstants.CREATED,
    booking
  }
}

const updated = (booking) => {
  return {
    type: bookingConstants.UPDATED,
    booking
  }
}

const deleted = () => {
  return {
    type: bookingConstants.DELETED
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: bookingConstants.CLEARED
    });
  };
}

const Get = (query, id) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Retrieving Bookings'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
        paging
      } = await GetBooking(core, account.id, id, Object.assign(query, { verbose: true }));
      let {
        booking,
        bookings,
        requests
      } = state.booking;

      if (Array.isArray(data)){
        if (query.requests) requests = { data, paging };
        else bookings = { data, paging };
      } else booking = data;
      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(booking, bookings, requests));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (booking, item) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const { account } = item.owner;
      const {
        meta,
        data
      } = await CreateBooking(core, account.id, booking, item, { verbose: true });

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (booking) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Updating Booking'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await UpdateBooking(core, account.id, booking, { verbose: true });

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (booking) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Deleting booking'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const { meta } = await DeleteBooking(core, account.id, booking);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const booking = {
  Get,
  Create,
  Update,
  Delete,
  Clear
};
