import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { QueryProcess } from './query';
import {
  withRouter
} from "react-router-dom";
import {
  alert,
  loading,
  activation,
  app,
  authenticate,
  invitation,
  recovery,
  notification
} from './actions';
import {
  appConstants,
  authConstants,
  alertConstants,
  loadAnimationConstants
} from './constants';
import {
  Alert,
  AddButton,
  Loader,
  Modal,
  NavBar,
  SideBar,
  AddFriend,
  AddItem
} from './components';
import Routes from './routes';
import RedeemIcon from '@material-ui/icons/Redeem';
import EmailIcon from '@material-ui/icons/Email';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      addItems: [
        {
          name: 'Add Item',
          icon: <RedeemIcon />
        },
        {
          name: 'Invite Friend',
          icon: <EmailIcon />
        }
      ],
      modal: undefined
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.refresh = this.refresh.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.addSelected = this.addSelected.bind(this);
    this.showModal = this.showModal.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
    this.onNotificationClicked = this.onNotificationClicked.bind(this);
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    this.setState({ pathname });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      app,
      auth,
      alert,
      history,
      endLoading,
      location,
      session,
      getNotifications,
      logout
    } = this.props;
    const { pathname } = this.state;
    const sso = localStorage.getItem('sso');

    if (prevProps.app !== app
      && app.type === appConstants.INITIALIZED) {
      if (!sso) {
        return QueryProcess(this.props);
      }
      return this.refresh();
    }

    if (prevProps.auth !== auth
      && auth.type === authConstants.SUCCESS) {
      if (pathname
        && (pathname !== '/login'
          && pathname !== '/')) {
        history.push(pathname);
        this.setState({ pathname: null });
        return endLoading();
      }
      history.push('/search');
      this.setState({ pathname: null });
      return endLoading();
    }

    if (session.core) {
      if (prevProps.location !== location) getNotifications(null, { all: true });
    }

    if (alert.message && alert.message === "Bearer token is expired.") {
      if (sso) {
        return this.refresh();
      }
      logout();
      return history.push('/');
    }
  }

  onNotificationClicked(n) {
    const { deleteNotifications } = this.props;

    deleteNotifications(n);
  }

  async refresh() {
    const {
      beginLoading,
      refreshLogin,
      endLoading,
      onError
    } = this.props;
    let sso = localStorage.getItem('sso');

    if (sso) {
      try {
        const { refresh_token } = JSON.parse(sso);

        if (refresh_token) {
          beginLoading('Authenticating...');
          return refreshLogin(refresh_token);
        }
      } catch (e) {
        endLoading();
        return onError(e.message);
      }
    }
  }

  toggleMenu(open) {
    open = open ? open : !this.state.showMenu;
    this.setState({ showMenu: open });
  }

  sendInvite(invite) {
    const { sendInvitation } = this.props;

    sendInvitation(invite);
    return this.setState({ modal: undefined });
  }

  addSelected(select) {
    if (select === 'Add Item') {
      return this.setState({
        modal: {
          title: 'Add New Item',
          content: <AddItem onDismiss={ () => this.setState({ modal: undefined }) } />
        }
      });
    } else if (select === 'Invite Friend') {
      return this.setState({
        modal: {
          title: 'Invite New Friend',
          content: <AddFriend onSubmit={ this.sendInvite } title='Invite Friend' />
        }
      });
    }

    return this.setState({ modal: undefined });
  }

  openNotification() {
    const {
      alert,
      clearAlert
    } = this.props
    const {
      type,
      message
    } = alert;
    const heading = type === alertConstants.ERROR ? 'Oops!' : 'Success!';
    const variant = type === alertConstants.ERROR ? 'error' : 'success';

    notification[variant]({
      message: heading,
      description: message
    });

    return clearAlert();
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({modal: undefined})}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  render() {
    const {
      load,
      session
    } = this.props;
    const {
      showMenu,
      addItems
    } = this.state;
    const loader = load.type === loadAnimationConstants.BEGIN ? <Loader /> : null;
    let homelink = '/';
    let addButton;

    const showSideBar = session.core !== undefined;
    if (showSideBar) addButton = <AddButton items={addItems} onItemSelect={this.addSelected}/>;

    return (
      <div className="App">
        { loader }
        { this.showModal() }
        <SideBar setOpen={this.toggleMenu} open={showMenu} />
        <Container maxWidth className={showMenu ? 'content-container-menu-open' : 'content-container'}>
          <NavBar brand={'Partizlice'} homelink={homelink} navClicked={this.toggleMenu} open={showMenu} onNotificationClicked={this.onNotificationClicked} />
          <Alert />
          <Routes />
          { addButton }
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  clearAlert: alert.clear,
  refreshLogin: authenticate.refreshLogin,
  logout: authenticate.logout,
  init: app.init,
  activateCode: activation.code,
  recoveryCode: recovery.code,
  clearAuth: authenticate.clear,
  beginLoading: loading.begin,
  endLoading: loading.end,
  sendInvitation: invitation.Create,
  getNotifications: notification.Get,
  deleteNotifications: notification.Delete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
