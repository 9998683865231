import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { QueryParser } from '../../query';
import {
  loading,
  item
} from '../../actions';
import { itemConstants } from '../../constants';
import {
  Search,
  SearchItemsViewer
} from '../../components';
import {
  Col,
  Row
} from 'react-bootstrap';
import './SearchPage.css';

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {
        data: [],
        paging: {
          limit: 10,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onAccountClick = this.onAccountClick.bind(this);
    this.handleItemClicked = this.handleItemClicked.bind(this);
  }

  componentDidMount() {
    const {
      beginLoading,
      searchItem
    } = this.props;
    const {
      limit,
      offset
    } = this.state.items.paging;

    const { keyword } = QueryParser(this.props);

    if (keyword && keyword.length > 0) {
      beginLoading('Loading Items');
      this.setState({ search: null });
      return searchItem({
        search: keyword,
        limit,
        offset
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      endLoading,
      location,
      beginLoading,
      searchItem
    } = this.props;
    const {
      limit,
      offset
    } = this.state;

    if (prevProps.item !== item) {
      if (item.type === itemConstants.RETRIEVED) {
        this.setState({ items: item.items });
        return endLoading();
      }
    }

    if (prevProps.location !== location) {
      const { keyword } = QueryParser(this.props);

      if (keyword && keyword.length > 0) {
        this.setState({ search: null });
        const message = keyword.length > 0 ? `Searching for ${keyword}...` : 'loading';

        beginLoading(message);
        return searchItem({
          search: keyword,
          limit,
          offset
        });
      }
    }
  }

  onSearchFieldChange(search) {
    this.setState({ search });
  }

  onSearchSubmit() {
    const { history } = this.props;
    const { search } = this.state;

    if (search && search.length > 0) history.push(`/search?keyword=${search}`);
  }

  handleItemClicked(i) {
    const { history } = this.props;

    return history.push(`/item/${i.id}`);
  }

  onAccountClick(account) {
    const { history } = this.props;

    return history.push(`/account/${account.name}`);
  }

  render() {
    const { keyword } = QueryParser(this.props);
    const { items } = this.state;
    let body;

    if (keyword && keyword.length > 0) {
      if (items.data.length > 0) body = <SearchItemsViewer items={items.data} onItemClicked={this.handleItemClicked} onAvatarClicked={this.onAccountClick} />
      else {
        body = (
          <Row className='empty-message'>
            <Col md={{ span: 6, offset: 3 }}>
              <Search placeholder='What are you looking for?' onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
              <br />
              <h3>{`No results found for '${keyword}'`}</h3>
            </Col>
          </Row>
        )
      }
    } else {
      body = (
        <Row className='empty-message'>
          <Col md={{ span: 6, offset: 3 }}>
            <Search placeholder='What are you looking for?' onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
            <br />
            <h5>Type something above and see if your friends are lending it out.</h5>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          { body }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  searchItem: item.Get,
  beginLoading: loading.begin,
  endLoading: loading.end,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPage));
