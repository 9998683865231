import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import ColorIcon from '../../color-icon';
import './SideBarItem.css';

class SideBarButton extends Component {
  constructor(props) {
    super(props);
    this.onHover = this.onHover.bind(this);
    this.offHover = this.offHover.bind(this);
    this.state = {
      hover: false
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    return this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onHover(e) {
    e.preventDefault();
    this.setState({hover: true});
  }

  offHover(e) {
    e.preventDefault();
    this.setState({hover: false});
  }

  render() {
    const {
      color,
      onClick,
      size,
      active,
      title
    } = this.props;
    const { hover } = this.state;
    const icon = color ? (
      <ListItemIcon>
        <ColorIcon color={color} size={size} filled={active || hover}/>
      </ListItemIcon>
    ) : null;

    return (
      <ListItem button onClick={onClick} type="submit" className="sidebar-btn" onMouseEnter={this.onHover} onMouseLeave={this.offHover}>
        { icon }
        <ListItemText primary={title} />
      </ListItem>
    );
  }
}

PropTypes.SideBarButton = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default SideBarButton;
