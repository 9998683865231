const RecoveryEmail = async (core, email) => {
  const { requests } = core;

  try {
    const {
      meta,
      data
    } = await requests.recovery.recoverAccountByEmail(email);

    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const RecoverySms = async (core, phone) => {
  const { requests } = core;

  try {
    const {
      meta,
      data
    } = await requests.recovery.recoverAccountBySms(phone);

    return Promise.resolve({
      meta,
      data: data.map(a => a.export())
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const ResetPassword = async (core, code, password) => {
  const special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const lowercase = /[a-z]/;
  const uppercase = /[A-Z]/;
  const numbers = /[0-9]/;

  if (password.length >= 8
    && special.test(password)
    && lowercase.test(password)
    && uppercase.test(password)
    && numbers.test(password)) {

    const { requests } = core;

    try {
      const {
        meta,
        data
      } = await requests.recovery.resetPassword(code, password);

      return Promise.resolve({
        meta,
        data: data.map(a => a.export())
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject(new Error('Password must be minimum 8 character length with special, uppercase, lowercase and numeric values.'));
}

export {
  RecoveryEmail,
  RecoverySms,
  ResetPassword
}
