const GetInvitation = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const {
      meta,
      data,
      paging
    } = await requests.invitation.get(account, id, {}, query);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateInvitation = async (core, account, invitation) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.invitation.create(new models.Invitation(invitation), account.id);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateInvitation = async (core, i, query) => {
  const {
    models,
    requests
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.invitation.update(new models.Invitation(i), null, query);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteInvitation = async (core, i, account) => {
  const {
    models,
    requests
  } = core;

  try {
    const { meta } = await requests.invitation.delete(new models.Invitation(i), account);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteInvitationBatch = async (core, ids, account) => {
  const { requests } = core;

  try {
    const { meta } = await requests.invitation.batchDelete(ids, account.id);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

const InvitationCode = async (core, code) => {
  const { requests } = core;

  try {
    const {
      meta,
      data
    } = await requests.invitation.code(code);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetInvitation,
  CreateInvitation,
  UpdateInvitation,
  DeleteInvitation,
  DeleteInvitationBatch,
  InvitationCode
}
