import { bookingConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case bookingConstants.REQUESTING:
    case bookingConstants.RETRIEVED:
    case bookingConstants.CREATED:
    case bookingConstants.UPDATED:
    case bookingConstants.DELETED:
      return {...state, ...action};
    default:
      return state;
  }
};
