import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form
} from 'react-bootstrap';
import {
  alert,
  loading,
  booking
} from '../../../actions';
import {} from '../../../constants';
import { DatePicker } from '../../../components';
import './BookItem.css';

class BookItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item.item
    };
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
    this.onMessageFieldChange = this.onMessageFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  submitButtonClicked(e) {
    e.preventDefault();
    const {
      message,
      item,
      date
    } = this.state;
    const {
      beginLoading,
      createBooking,
      onError,
    } = this.props;

    if (message) {
      beginLoading('Creating Booking request');
      createBooking({
        start_time: date,
        message
      }, item);
      return this.setState({processing: true});
    }
    return onError('A message is required to book this item');
  }

  onMessageFieldChange(e) {
    e.preventDefault();
    let { message } = this.state;

    message = e.target.value;
    this.setState({ message });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  render() {
    const {
      date,
      message
    } = this.state;

    return (
      <Form>
        <Form.Group>
          <DatePicker date={date} onDateSelected={this.handleDateChange} label='Pick-up date'/>
        </Form.Group>

        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder='What would you like to do with this item'
            rows={3}
            value={message}
            onChange={this.onMessageFieldChange}/>
        </Form.Group>

        <Button
          onClick={this.submitButtonClicked}
          disabled={!message || message.length < 1}
          className='item-submit-button'>
          Book
        </Button>
      </Form>
    );
  }
}

BookItem.defaultProps = {
  item: {}
};

BookItem.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  createBooking: booking.Create,
  beginLoading: loading.begin,
  endLoading: loading.end,
  onSuccess: alert.success,
  onError: alert.error
};

export default connect(mapStateToProps, mapDispatchToProps)(BookItem);
