import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  CardGroup,
  Card,
  Col,
  Row,
  Jumbotron
} from 'react-bootstrap';
import {
  alert
} from '../../actions';
import { DeleteFile } from '../../services';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import Alert from '../alert';
import Dropzone from '../drop-zone';
import Modal from '../modal';
import DeleteForm from '../delete-form';
import {
  IoIosCopy,
  IoIosTrash
} from 'react-icons/io';
import './MediaSelector.css';
import { copyStringToClipboard } from '../../utils';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

class MediaSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      list: props.list ? props.list : []
    };
    this.renderMedias = this.renderMedias.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showUploadModal = this.showUploadModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.renderLibrary = this.renderLibrary.bind(this);
    this.renderStartMessage = this.renderStartMessage.bind(this);
    this.onMediaOrderChange = this.onMediaOrderChange.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleUploadComplete(file) {
    const { list } = this.state;
    this.hideModal();
    list.push(file);
    this.setState({ list });
    return this.props.onMediaUpload(list);
  }

  handleError(message) {
    this.props.executeOnError(message);
  }

  onMediaOrderChange(sourceId, sourceIndex, targetIndex, targetId) {
    let { list } = this.state;
    list = swap(list, sourceIndex, targetIndex);
    this.setState({ list });
    return this.props.onMediaListUpdate(list);
  }

  async handleDelete(e, i) {
    const { list } = this.state;
    const file = list[i];

    if (file) {
      this.setState({
        showModal: true,
        modalTitle: 'Delete File',
        modalContent: <DeleteForm data={{name: 'delete', file: file}} onDelete={e => this.deleteMedia(i, file)} onError={this.handleError} />
      });
    }
  }

  async deleteMedia(i, file) {
    const { app } = this.props.app;
    const { list } = this.state;

    try {
      const res = await DeleteFile(app, file);

      list.splice(i, 1);
      this.setState({ list, showModal: false });
      this.props.executeOnSuccess(res.message);
      return this.props.onMediaUpload(list);
    } catch (e) {
      return this.props.executeOnError(e.message);
    }
  }

  /**
   * Modal
   * @return {[type]} [description]
   */
  showUploadModal() {
    this.setState({
      showModal: true,
      modalTitle: 'Upload Media',
      modalContent: (<Dropzone
        fileKey={this.props.fileKey}
        onComplete={this.handleUploadComplete}
        onError={this.handleError}
        accept="image/*,video/*" />)
    });
  }

  hideModal() {
    this.props.onClear();
    this.setState({ showModal: false });
  }

  handleCopy(e, i) {
    e.preventDefault();
    const { list } = this.state;
    copyStringToClipboard(list[i].url);
    this.props.executeOnSuccess('Copied image link to clipboard.');
  }

  renderMedias() {
    const { list } = this.state;
    return list.map((item, i) => {
      return (
        <GridItem key={i}>
          <Card>
            <Card.Img className="media-selector-image" variant="top" src={item.url} />
            <Card.Footer>
              <Button className='card-control-buttons' variant="outline-primary" onClick={e => this.handleCopy(e, i)}>
                <IoIosCopy />
              </Button>
              <Button className='card-control-buttons' variant="outline-danger" onClick={e => this.handleDelete(e, i)}>
                <IoIosTrash />
              </Button>
            </Card.Footer>
          </Card>
        </GridItem>
      );
    });
  }

  renderLibrary() {
    return (
      <GridContextProvider onChange={this.onMediaOrderChange}>
        <GridDropZone
          id="items"
          boxesPerRow={4}
          rowHeight={270}
          style={{ height: "1200px" }}
        >
          { this.renderMedias() }
        </GridDropZone>
      </GridContextProvider>
    );
  }

  renderStartMessage() {
    return (
      <Jumbotron className='media-start-message'>
        <h1>No Files Found</h1>
        <p>
          Click below to upload your first media file.
        </p>
        <p>
          <Button variant="primary" onClick={this.showUploadModal}>Upload</Button>
        </p>
      </Jumbotron>
    );
  }

  render() {
    const { alert } = this.props;
    const {
      showModal,
      modalTitle,
      modalContent,
      list
    } = this.state;
    const content = list.length > 0 ? this.renderLibrary() : this.renderStartMessage();

    return (
      <Row className='section-row'>
        <Col>
          <Row>
            <Col>
              <Button className='upload-button' onClick={this.showUploadModal}>
                Upload
              </Button>
            </Col>
          </Row>
          <Modal
            show={showModal}
            title={modalTitle}
            onHide={this.hideModal}
          >
            { alert.message ? <Alert /> : null }
            { modalContent }
          </Modal>
          { content }
        </Col>
      </Row>
    );
  }
}

MediaSelector.propTypes = {
  fileKey: PropTypes.string,
  list: PropTypes.array.isRequired,
  onMediaListUpdate: PropTypes.func.isRequired,
  onMediaUpload: PropTypes.func
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  executeOnError: alert.error,
  executeOnSuccess: alert.success,
  onClear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSelector);
