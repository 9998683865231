import { profileConstants } from '../constants';
import {
  App,
  SessionCore,
  GetAccountByName,
  GetUser,
  UpdateAccount,
  UpdateUser,
  DeleteAccount,
  DeleteUser
} from '../services';
import { authenticate } from './auth.actions';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: profileConstants.REQUESTING }
}

const retrieved = (account, user) => {
  return {
    type: profileConstants.RETRIEVED,
    account,
    user
  }
}

const updated = (account, user) => {
  return {
    type: profileConstants.UPDATED,
    account,
    user
  }
}

const deleted = () => {
  return {
    type: profileConstants.DELETED
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: profileConstants.CLEARED
    });
  };
}

const Get = (name) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      let core = SessionCore(state);
      if (!core) core = await App(state);
      const a = await GetAccountByName(core, name);
      const account = a.data[0];

      const u = await GetUser(core, account.id);
      const user = u.data[0];

      return dispatch(retrieved(account, user));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (account, user) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);

      account = await UpdateAccount(core, account);
      user = await UpdateUser(core, user);

      dispatch(alert.success('Profile has been updated'));
      return dispatch(updated(account, user));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (account, user) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);

      if (!account) {
        dispatch(loading.end());
        return dispatch(alert.error('Account not found'));
      }
      await DeleteAccount(core, account);
      await DeleteUser(core, user);
      dispatch(deleted());
      dispatch(loading.end());
      dispatch(authenticate.logout());
      return dispatch(alert.success('Account has been deleted'));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const profile = {
  Get,
  Update,
  Delete,
  Clear
};
