import { recoveryConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case recoveryConstants.BEGIN:
    case recoveryConstants.SUCCESS:
    case recoveryConstants.CODE:
      return {...state, ...action};
    case recoveryConstants.CLEARED:
      return {};
    default:
      return state;
  }
}
