import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Col
} from 'react-bootstrap';
import './EditProfile.css';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      user: props.user
    };
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
    this.onAccountNameFieldChange = this.onAccountNameFieldChange.bind(this);
    this.onFirstNameFieldChange = this.onFirstNameFieldChange.bind(this);
    this.onLastNameFieldChange = this.onLastNameFieldChange.bind(this);
    this.onEmailFieldChange = this.onEmailFieldChange.bind(this);
    this.onPhoneFieldChange = this.onPhoneFieldChange.bind(this);
  }

  submitButtonClicked(e) {
    e.preventDefault();
    const {
      account,
      user
    } = this.state;

    if (user.email !== '' && account.name !== '') {
      return this.props.onSubmit({
        user,
        account
      });
    }
  }

  onAccountNameFieldChange(e) {
    e.preventDefault();
    let { account } = this.state;

    account.name = e.target.value;
    this.setState({ account });
  }

  onFirstNameFieldChange(e) {
    e.preventDefault();
    let { user } = this.state;

    user.first_name = e.target.value;
    this.setState({ user });
  }

  onLastNameFieldChange(e) {
    e.preventDefault();
    let { user } = this.state;

    user.last_name = e.target.value;
    this.setState({ user });
  }

  onEmailFieldChange(e) {
    e.preventDefault();
    let { user } = this.state;

    user.email = e.target.value;
    this.setState({ user });
  }

  onPhoneFieldChange(e) {
    e.preventDefault();
    let { user } = this.state;

    user.phone = e.target.value;
    this.setState({ user });
  }

  render() {
    const {
      account,
      user
    } = this.state;
    return (
      <Form>
        <Form.Group>
          <Form.Label>Account Name</Form.Label>
          <Form.Control
          value={account.name}
          type="text"
          placeholder="Account Name"
          onChange={this.onAccountNameFieldChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>User Details</Form.Label>
          <Form.Row>
            <Col>
              <Form.Label>First Name</Form.Label>
              <Form.Control
              value={user.first_name}
              type="text"
              placeholder="First name"
              onChange={this.onFirstNameFieldChange} />
            </Col>
            <Col>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
              value={user.last_name}
              type="text"
              placeholder="Last name"
              onChange={this.onLastNameFieldChange} />
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Label>Contact Info</Form.Label>
          <Form.Row>
            <Col>
              <Form.Label>Email</Form.Label>
              <Form.Control
              value={user.email}
              type="text"
              placeholder="Email"
              onChange={this.onEmailFieldChange} />
            </Col>
            <Col>
              <Form.Label>Phone</Form.Label>
              <Form.Control
              value={user.phone}
              type="text"
              placeholder="Phone"
              onChange={this.onPhoneFieldChange} />
            </Col>
          </Form.Row>
        </Form.Group>

        <Button
          onClick={this.submitButtonClicked}
          className='profile-update-button'>
          Update
        </Button>
      </Form>
    );
  }
}

EditProfile.defaultProps = {
  account: {
    name : null
  },
  user: {
    firstName : null,
    lastName : null,
    email: null,
    phone: null
  }
};

EditProfile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  account: PropTypes.object,
  user: PropTypes.object,
};

export default EditProfile;
