import { messageConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case messageConstants.REQUESTING:
    case messageConstants.RETRIEVED:
    case messageConstants.CREATED:
    case messageConstants.UPDATED:
    case messageConstants.DELETED:
      return {...state, ...action};
    default:
      return state;
  }
};
