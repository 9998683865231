import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  alert,
  loading,
  booking
} from '../../actions';
import {
  Locator,
  Messenger,
  Modal
} from '../../components';
import { bookingConstants } from '../../constants';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import {
  Avatar,
  Card,
  CardContent
} from '@material-ui/core';
import './BookingPage.css';

class BookingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
    this.showDetailsModal = this.showDetailsModal.bind(this);
    this.showOtherModal = this.showOtherModal.bind(this);
    this.loadBooking = this.loadBooking.bind(this);
    this.renderOther = this.renderOther.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showSetLocationModal = this.showSetLocationModal.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.renderMessenger = this.renderMessenger.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.onApproveClicked = this.onApproveClicked.bind(this);
    this.onDeclinedClicked = this.onDeclinedClicked.bind(this);
  }

  componentDidMount() {
    const {
      session,
      history
    } = this.props;
    if (!session.core) {
      return history.push('/login');
    }
    return this.loadBooking();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      booking,
      endLoading
    } = this.props;

    if (prevProps.booking !== booking) {
      if (booking.type === bookingConstants.RETRIEVED) {
        this.setState({ booking: booking.booking });
        return endLoading();
      }

      if (booking.type === bookingConstants.UPDATED) {
        this.setState({ booking: booking.booking });
        return endLoading();
      }
    }
  }

  loadBooking() {
    const {
      beginLoading,
      location,
      getBooking,
    } = this.props;
    const { pathname } = location;
    const id = pathname.split('/booking/')[1];

    if (id) {
      beginLoading('Loading Request');
      return getBooking({ verbose: true }, id);
    }
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  showDetailsModal(e) {
    e.preventDefault();
    return this.setState({
      modal: {
        title: 'Details',
        content: this.renderDetails()
      }
    });
  }

  showOtherModal(e) {
    e.preventDefault();
    const { booking } = this.state;
    const { session } = this.props;
    const { owner } = booking;
    const title = owner.account.id === session.account.id ? 'Borrower' : 'Lender';

    return this.setState({
      modal: {
        title,
        content: this.renderOther()
      }
    });
  }

  setLocation(coordinates, data) {
    let { booking } = this.state;
    const { updateBooking } = this.props;

    booking = {...booking, ...data};
    booking.pick_up_location = { coordinates };

    updateBooking(booking);
  }

  showSetLocationModal(data) {
    const {
      session,
      onError
    } = this.props;
    let coords;

    if (session.location) {
      const {
        latitude,
        longitude
      } = session.location.coords;
      coords = [latitude, longitude];
    }

    return this.setState({
      modal: {
        title: 'Set Pickup Location',
        content: <Locator coords={coords} setLocation={e => this.setLocation(e, data)} onError={onError} />
      }
    });
  }

  renderOther() {
    const { session } = this.props;
    const { booking } = this.state;
    const {
      owner,
      renter
    } = booking;
    const other = owner.account.id === session.account.id ? renter : owner;
    const title = owner.account.id === session.account.id ? 'Borrower' : 'Lender';

    return (
      <div>
        <h5>{title}</h5>
        <br />
        <Avatar className='user-description-avatar' src={other.user.avatar ? other.user.avatar.url : null}>{ other.account.name.substring(0,2).toUpperCase() }</Avatar>
        <h5 className='user-name'>{other.account.name}</h5>
      </div>
    );
  }

  onApproveClicked(e) {
    e.preventDefault();

    this.showSetLocationModal({ status: 'approved' });
  }

  onDeclinedClicked(e) {
    e.preventDefault();

    console.log('Declined');
  }

  renderStatus() {
    const { session } = this.props;
    const { booking } = this.state;
    const { status } = booking;
    const { owner } = booking;

    if (owner.account.id === session.account.id) {
      // let appBtns;
      // if (status === 'REQUESTED'){
      //   appBtns = (
      //     <div>
      //       <Button variant="primary" onClick={this.onApproveClicked}>Approve</Button>
      //       <br />
      //       <Button variant="danger" onClick={this.onApproveClicked}>Decline</Button>
      //     </div>
      //   );
      // }

      return (
        <div>
          <h4>Current status</h4>
          <h5>{status === 'REQUESTED' ? 'PENDING' : status}</h5>
        </div>
      );
    }

    return (
      <div>
        <h4>Current Status</h4>
        <h5>{status}</h5>

        <p>{status === 'REQUESTED' ? 'Awaiting approval' : ''}</p>
      </div>
    );
  }

  renderDetails() {
    const { session } = this.props;
    const { booking } = this.state;
    const {
      owner,
      item
    } = booking;
    const title = owner.account.id === session.account.id ? `lend ${ item.name }` : `borrow ${ item.name }`;
    const date = new Date(booking.start_time);
    // const map = booking.pick_up_location ? <Map /> : <Button onClick={this.showSetLocationModal}>Set pick up location</Button>;
    return (
      <div className='details-container'>
        <Card variant="outlined">
          <CardContent>
            <h5>Item</h5>
            <br />
            <h8 className='date-string'>{`Start: ${date.toLocaleString('en-us', { month: 'short' })}. ${date.getDate()}, ${date.getFullYear()}`}</h8>
            <h4>Request to { title }</h4>
            <br />
            <img className='item-main-image' src={item.media[0].url} alt={item.media[0].id} />
          </CardContent>
        </ Card>
      </div>
    );
  }

  renderMessenger() {
    const { session } = this.props;
    const { booking } = this.state;
    const {
      owner,
      messages,
      status
    } = booking;
    let appBtns;

    if (owner.account.id === session.account.id) {
      if (status === 'REQUESTED') {
        appBtns = (
          <Card variant="outlined">
            <CardContent>
              <h5>Actions</h5>
              <br />
              <div className='action-buttons'>
                <Button variant="primary" className='left-button' onClick={this.onApproveClicked}>Approve</Button>
                <Button variant="danger" className='right-button' onClick={this.onDeclinedClicked}>Decline</Button>
              </div>
            </CardContent>
          </ Card>
        );
      }
    }

    return (
      <div>
        <Card variant="outlined">
          <CardContent>
            <h5 className='componet-title'>Message Thread</h5>
            <Messenger messengerId={messages} session={session} />
          </CardContent>
        </ Card>
        <br />
        {appBtns}
      </ div>
    );
  }

  render() {
    const { session } = this.props;
    const { booking } = this.state;

    if (booking) {
      const { owner } = booking;
      const title = owner.account.id === session.account.id ? 'Borrower' : 'Lender';

      return (
        <div>
          { this.showModal() }
          <Row className='mobile-buttons'>
            <Button className='left-button' onClick={this.showOtherModal}>{title}</ Button>
            <Button className='right-button' onClick={this.showDetailsModal}>Details</ Button>
          </Row>
          <br />
          <br />
          <Row>
            <Col md="3" className='hideable-section'>
              <Card variant="outlined">
                <CardContent>
                 { this.renderOther() }
                </CardContent>
              </ Card>
              <br />
              <Card variant="outlined">
                <CardContent>
                 { this.renderStatus() }
                </CardContent>
              </ Card>
            </Col>
            <Col md="6" className='component-section'>
              { this.renderMessenger() }
            </Col>
            <Col md="3" className='hideable-section'>
              { this.renderDetails() }
            </Col>
          </Row>
        </div>
      );
    }
    return <h1>No booking Found</h1>;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  onError: alert.error,
  beginLoading: loading.begin,
  endLoading: loading.end,
  getBooking: booking.Get,
  updateBooking: booking.Update
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookingPage));
