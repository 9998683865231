import queryString from 'query-string';

const QueryParser = props => {
  const { location } = props;
  const { search } = location;

  return queryString.parse(search);
};

const QueryCheck = (props) => {
  return Object.keys(QueryParser(props)).length > 0;
}

const QueryProcess = props => {
  const {
    app,
    session,
    location,
    history
  } = props;

  if (app && location) {
    const queries = QueryParser(props);

    if (!session.core) {
      const {
        activate,
        reset,
        invitation
      } = queries;
      // if (invitation) {
      //   props.proposalCode(invitation);
      //   return history.push('/register');
      // } else if (activate) {
      //   props.activateCode(activate);
      //   return history.push('/login');
      // } else if (reset) {
      //   props.recoveryCode(reset);
      //   return history.push('/reset');
      // }
    }
  }
  return false;
};

export {
  QueryCheck,
  QueryParser,
  QueryProcess
}
