import { clientConstants } from '../constants';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';
import {
  SessionCore,
  SessionAccount,
  GetClient,
  CreateClient,
  UpdateClient,
  DeleteClient
} from '../services';

const requesting = () => {
  return { type: clientConstants.REQUESTING };
}

const retrieved = (client, clients) => {
  return {
    type: clientConstants.RETRIEVED,
    client,
    clients
  };
}

const created = (client) => {
  return {
    type: clientConstants.CREATED,
    client
  };
}

const updated = (client) => {
  return {
    type: clientConstants.UPDATED,
    client
  }
}

const deleted = () => {
  return {
    type: clientConstants.DELETED
  }
}

const Get = (id, query) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      let {
        client,
        clients
      } = state.client;
      const {
        meta,
        data,
        paging
      } = await GetClient(core, account.id, id);

      if (Array.isArray(data)) clients = { data, paging };
      else client = data;

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(client, clients));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (client) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateClient(core, account.id, client);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (client) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state, core);

      if (!account) {
        dispatch(loading.end());
        return dispatch(alert.error('Account not found'));
      }
      const {
        meta,
        data
      } = await UpdateClient(core, client);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (client) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state, core);

      if (!account) {
        dispatch(loading.end());
        return dispatch(alert.error('Account not found'));
      }
      const { meta } = await DeleteClient(core, account.id, client);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const client = {
  Get,
  Create,
  Update,
  Delete
};
