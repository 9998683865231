import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { QueryParser } from '../../query';
import { authenticate } from '../../actions';
import { authenticated } from '../../menu';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  InputBase
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import './NavBar.css';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  appBarShift: {

  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.15),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const NavbarComponent = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notificationEl, setNotificationEl] = React.useState(null);
  const {
    session,
    history,
    notification,
    brand,
    location,
  } = props;
  const {
    pathname,
    search
  } = location;
  const { keyword } = QueryParser(props);
  const [searchQuery, setSearchQuery] = React.useState(keyword);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationOpen = Boolean(notificationEl);

  const handleProfileMenuOpen = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (e) => {
    e.preventDefault();
    setMobileMoreAnchorEl(e.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };

  const handleNotificationMenuOpen = (e) => {
    e.preventDefault();
    setNotificationEl(e.currentTarget);
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    handleMobileMenuClose();
    props.logout();
    return props.history.push('/');
  };

  const menuId = 'primary-search-account-menu';
  const { account } = session;

  const navigateTo = (e, path) => {
    e.preventDefault();
    setAnchorEl(null);
    setSearchQuery(null);
    handleMobileMenuClose();
    return history.push(path);
  }

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  }

  const searchKeyDown = (e) => {
    if (searchQuery && e.key.toLowerCase() === 'enter' && searchQuery.length > 0) {
      setSearchQuery('');
      return history.push(`/search?keyword=${searchQuery}`);
    }
  }

  const onNotificationClick = (e, n) => {
    e.preventDefault();
    handleNotificationClose();

    const {
      id,
      domain
    } = n.link;

    if (props.onNotificationClicked) props.onNotificationClicked(n);

    switch (domain) {
      case 'invitation':
        return history.push(`/friends?key=invitations`);
      case 'booking':
        return history.push(`/booking/${id}`);
      default:
    }
  }

  const notificationCount = notification.notifications ? notification.notifications.data.length : 0;

  let notificationsList;
  let links;
  let searchBar;
  if (session.core) {
    if (notificationCount > 0) {
      notificationsList = (
        <Menu
          anchorEl={notificationEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isNotificationOpen}
          onClose={handleNotificationClose}
        >
        {
          notification.notifications.data.map((n, i) => {
            const {
              sender
            } = n;
            const {
              account,
              user
            } = sender;

            return (
              <ListItem key={i} alignItems="flex-start" onClick={e => onNotificationClick(e, n)}>
                <ListItemAvatar>
                  <Avatar className='notification-image' alt={account.name}  src={user.avatar ? user.avatar.url : null}>
                    { account.name.substring(0,2).toUpperCase() }
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={n.link.domain}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {account.name}
                      </Typography>
                      {` — ${ n.message }`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })
        }
        </Menu>
      );
    }
    links = authenticated.map((l, i) => {
      if (l.path === '/logout') {
        return <MenuItem key={i} onClick={handleSignOut}>Logout</MenuItem>;
      } else if (l.path === '/account') {
        return (
          <MenuItem key={i} onClick={e => navigateTo(e, `${l.path}/${account.name}`)}>
            {l.title}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={i} onClick={e => navigateTo(e, `${l.path}`)}>
            {l.title}
          </MenuItem>
        );
      }
    });

    searchBar = (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search for item…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchQuery}
          onChange={onSearchChange}
          onKeyDown={searchKeyDown}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    );
    if (pathname === '/search' && search.length < 1) searchBar = null;;
  }
  const renderMenu = session.core ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      { links }
    </Menu>
  ) : null;

  // <MenuItem>
  //   <IconButton aria-label="show new mails" color="inherit">
  //     <Badge badgeContent={0} color="secondary">
  //       <MailIcon />
  //     </Badge>
  //   </IconButton>
  //   <p>Message</p>
  // </MenuItem>

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = session.core ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={e => navigateTo(e, `/account/${account.name}`)}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
        { session.user && session.user.avatar ? <Avatar className='nav-avatar-img' src={session.user.avatar.url} /> : <AccountCircle />}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleNotificationMenuOpen}>
        <IconButton aria-label="show new notifications" color="inherit">
          <Badge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <IconButton
          aria-label="Sign Out User"
          aria-controls="primary-logout-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  ) : null;

  // <IconButton aria-label="show new mails" color="inherit">
  //   <Badge badgeContent={0} color="secondary">
  //     <MailIcon />
  //   </Badge>
  // </IconButton>

  const rightButtons = session.core ? (
    <div className={classes.sectionDesktop}>
      <IconButton aria-label="show new notifications" onClick={handleNotificationMenuOpen} color="inherit">
        <Badge badgeContent={notificationCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
      { session.user && session.user.avatar ? <Avatar className='nav-avatar-img' src={session.user.avatar.url} /> : <AccountCircle />}
      </IconButton>
    </div>
  ) : null;

  return (
    <div className={classes.grow}>
      <AppBar className={props.open ? 'app-bar-shift' : null}
        position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={e => props.navClicked(!props.open)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            { brand }
          </Typography>
          { searchBar }
          <div className={classes.grow} />
          { rightButtons }
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={notificationCount} color="secondary">
                <MoreIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {notificationsList}
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  logout: authenticate.logout
};

NavbarComponent.propTypes = {
  brand: PropTypes.string.isRequired,
  homelink: PropTypes.string,
  navClicked: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarComponent));
