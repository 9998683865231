import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const DatePicker = (props) => {
  const {
    date,
    label
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label={label}
        value={date}
        onChange={props.onDateSelected}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.defaultProps = {
  date: Date.now()
};

DatePicker.propTypes = {
  date: PropTypes.object,
  label: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired
};

export default DatePicker;
