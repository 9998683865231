import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form
} from 'react-bootstrap';
import { Chip } from '@material-ui/core';
import {
  alert,
  item,
  loading
} from '../../actions';
import { FileUploader } from '../../services';
import { itemConstants } from '../../constants';
import DropZone from '../drop-zone';
import Alert from '../alert';
import './AddItemForm.css';

class AddItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: props.keywords,
      keyword: '',
      files: []
    };
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
    this.onNameFieldChange = this.onNameFieldChange.bind(this);
    this.onDescriptionFieldChange = this.onDescriptionFieldChange.bind(this);
    this.handleFilesDropped = this.handleFilesDropped.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.handleDeleteKeyword = this.handleDeleteKeyword.bind(this);
    this.onKeywordFieldChange = this.onKeywordFieldChange.bind(this);
    this.onKeywordEnter = this.onKeywordEnter.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      onSuccess,
      onDismiss,
      endLoading
    } = this.props;

    if (prevProps.item !== item) {
      if (item.type === itemConstants.CREATED) {
        return this.uploadFiles();
      }

      if (item.type === itemConstants.UPDATED) {
        onSuccess('Item Created');
        onDismiss();
        return endLoading();
      }
    }
  }

  handleImageError(e) {
    const { onError } = this.props;

    return onError(e.message);
  }

  handleFilesDropped(files) {
    return this.setState({ files });
  }

  async uploadFiles() {
    const {
      endLoading,
      onError,
      updateItem
    } = this.props;
    const { item } = this.props.item;
    const { app } = this.props.app;
    const { files } = this.state;

    try {
      const media = await Promise.all(files.map((f, i) => {
        return FileUploader(app, item.id, f.file, (progress) => f.progress = progress);
      }));
      
      item.media = media;
      return updateItem(item);
    } catch (e) {
      onError(e.message);
      return endLoading();
    }
  }

  submitButtonClicked(e) {
    e.preventDefault();
    const {
      name,
      description
    } = this.state;
    const {
      beginLoading,
      createItem,
      onError,
    } = this.props;

    if (name && description) {
      beginLoading('Creating Item');
      return createItem({
        name,
        description
      });
    }
    return onError('image, name and description are required to create a new item');
  }

  onNameFieldChange(e) {
    e.preventDefault();
    let { name } = this.state;

    name = e.target.value;
    this.setState({name});
  }

  onKeywordFieldChange(e) {
    e.preventDefault();
    let { keyword } = this.state;

    keyword = e.target.value;

    this.setState({ keyword });
  }

  onKeywordEnter(e) {
    let {
      keyword,
      keywords
    } = this.state;

    if (e.key.toLowerCase() === 'enter'
      && keyword.length > 0) {
      keywords.push(keyword);
      return this.setState({
        keyword: '',
        keywords
      });
    }
  }

  onDescriptionFieldChange(e) {
    e.preventDefault();
    let { description } = this.state;

    description = e.target.value;
    this.setState({description});
  }

  handleDeleteKeyword(e, i) {
    e.preventDefault();
    const { keywords } = this.state;

    keywords.splice(i, 1);
    this.setState({ keywords });
  }

  render() {
    const {
      name,
      description,
      keywords,
      keyword,
      files
    } = this.state;

    return (
      <Form>
        <Alert />

        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
          value={name}
          type="text"
          placeholder="Name"
          onChange={this.onNameFieldChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Images</Form.Label>
          <DropZone
            accept="image/*"
            files={files}
            onFilesDropped={this.handleFilesDropped}
            onError={this.handleImageError} />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Searchable Keywords
          </Form.Label>
          { keywords.length > 0 ? <br /> : null }
          { keywords.map((k, i) => <Chip key={i} label={k} onDelete={e => this.handleDeleteKeyword(e, i)} color="primary" />) }
          { keywords.length > 0 ? <br /> : null }
          <br />
          <Form.Control
          value={keyword}
          type="text"
          placeholder="attributes, keywords or tags"
          onChange={this.onKeywordFieldChange}
          onKeyDown={this.onKeywordEnter}
          />
          <Form.Text className="text-muted">
            Works like hashtags to add additional attiributes, allowing others to search easily
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Tells us something about the item"
            rows={3}
            value={description}
            onChange={this.onDescriptionFieldChange}/>
        </Form.Group>

        <Button
          onClick={this.submitButtonClicked}
          disabled={!name || !description || files.lenght < 1}
          className='item-submit-button'>
          Create
        </Button>
      </Form>
    );
  }
}

AddItemForm.defaultProps = {
  keywords: []
};

AddItemForm.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  keywords: PropTypes.array
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  createItem: item.Create,
  updateItem: item.Update,
  deleteItem: item.Delete,
  onSuccess: alert.success,
  onError: alert.error
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemForm);
