import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../alert';
import { Modal } from 'react-bootstrap';
import './Modal.css';

const ModalPopup = props => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          { props.title }
        </Modal.Title>
      </Modal.Header>
      <Alert />
      <Modal.Body>
        { props.children }
      </Modal.Body>
    </Modal>
  );
};

ModalPopup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ModalPopup;
