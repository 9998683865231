const GetBooking = async (core, account, id, query) => {
  const { requests } = core;

  try {
    const {
      meta,
      data,
      paging
    } = await requests.booking.get(account, id, null, query);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateBooking = async (core, account, booking, item, query) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data,
    } = await requests.booking.create(new models.Booking(booking), item, account, null, query);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateBooking = async (core, account, booking, query) => {
  const {
    models,
    requests
  } = core;

  try {
    booking = {...booking};

    if (typeof booking.item === 'object') {
      booking.item = booking.item.id;
    }

    if (typeof booking.owner === 'object') {
      booking.owner = booking.owner.account.id;
    }

    if (typeof booking.renter === 'object') {
      booking.renter = booking.renter.account.id;
    }

    const {
      meta,
      data,
    } =  await requests.booking.update(new models.Booking(booking), account, null, query);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteBooking = async (core, account, booking) => {
  const {
    models,
    requests
  } = core;

  try {
    const { meta } = await requests.booking.delete(new models.Booking(booking), account);

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetBooking,
  CreateBooking,
  UpdateBooking,
  DeleteBooking
}
