import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  app,
  alert,
  loading,
  profile,
  item
} from '../../actions';
import {
  profileConstants,
  itemConstants,
  appConstants
} from '../../constants';
import {
  DropZone,
  Search,
  ItemsViewer,
  Modal
} from '../../components';
import { FileUploader } from '../../services';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import { Avatar } from '@material-ui/core';
import './ProfilePage.css';
import EditProfile from './edit-profile';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      account: {
        name: ''
      },
      user: {
        avatar: {
          url: null
        }
      }
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleAvatarClick = this.handleAvatarClick.bind(this);
    this.handleAvatarUpdate = this.handleAvatarUpdate.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.showModal = this.showModal.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
    this.selectedEdit = this.selectedEdit.bind(this);
    this.selectedDelete = this.selectedDelete.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handleFilesDropped = this.handleFilesDropped.bind(this);
  }

  componentDidMount() {
    const {
      beginLoading,
      location,
      getProfile,
      app,
      init
    } = this.props;

    const name = location.pathname.substring(9);

    beginLoading('Loading Profile');
    if (app && app.app) {
      return getProfile(name);
    }
    return init();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      profile,
      item,
      endLoading,
      getItem,
      onSuccess,
      app,
      getProfile,
      location
    } = this.props;

    if (prevProps.app !== app
      && app.type === appConstants.INITIALIZED) {
        const name = location.pathname.substring(9);

        return getProfile(name);
    }

    if (prevProps.profile !== profile
      && (profile.type === profileConstants.RETRIEVED || profile.type === profileConstants.UPDATED)) {
      if (profile.type === profileConstants.UPDATED) onSuccess('Profile Updated!');

      this.setState({
        account: profile.account,
        user: profile.user
      });
      return getItem(null, null, profile.account.id);
    }

    if (prevProps.item !== item) {
      if (item.type === itemConstants.RETRIEVED) {
        this.setState({ items: item.items.data });
        return endLoading();
      }

      if (item.type === itemConstants.CREATED) {
        return getItem(null, null, profile.account.id);
      }
    }
  }

  onSearchFieldChange(search) {
    this.setState({ search });
  }

  onSearchSubmit() {
    const {
      getItem,
      beginLoading
    } = this.props;
    const {
      account,
      search
    } = this.state;

    beginLoading('Searching...');
    return getItem({ search }, null, account.id);
  }

  selectedItem(i) {
    const { history } = this.props;

    return history.push(`/item/${i.id}`);
  }

  selectedEdit(item) {
    console.log(item);
  }

  selectedDelete(item) {
    console.log(item);
  }

  async uploadFiles() {
    const {
      endLoading,
      onError,
      updateItem
    } = this.props;
    const { item } = this.props.item;
    const { app } = this.props.app;
    const { files } = this.state;

    try {
      const media = await Promise.all(files.map((f, i) => {
        return FileUploader(app, item.id, f.file, (progress) => f.progress = progress);
      }));

      item.media = media;
      return updateItem(item);
    } catch (e) {
      onError(e.message);
      return endLoading();
    }
  }

  handleFilesDropped(files) {
    return this.setState({ files });
  }

  handleAvatarClick(e) {
    e.preventDefault();
    const {
      account,
      files
    } = this.state;
    const { session } = this.props;

    if (account.id === session.account.id) {
      return this.setState({
        modal: {
          title: 'Update Profile Image',
          content: <DropZone accept="image/*" files={files} onFilesDropped={this.handleFilesDropped} onError={this.handleImageError} />
        }
      });
    }
  }

  handleAvatarUpdate(upload) {
    const {
      account,
      user
    } = this.state;
    const {
      updateProfile,
      beginLoading
    } = this.props;
    beginLoading('Updating Account Details');
    this.setState({ modal: null });
    updateProfile(account, Object.assign(user, { avatar: upload }));
  }

  handleImageError(e) {
    this.setState({ modal: null });
    this.props.onError(e);
  }

  handleUpdate(update) {
    const {
      account,
      user
    } = update;
    const {
      updateProfile,
      beginLoading
    } = this.props;

    beginLoading('Updating Account Details');
    this.setState({ modal: null });
    updateProfile(account, user);
  }

  handleEditClick(e) {
    e.preventDefault();

    const {
      account,
      user
    } = this.state;

    return this.setState({
      modal: {
        title: 'Edit My Details',
        content: <EditProfile onSubmit={this.handleUpdate} account={account} user={user} />
      }
    });
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  render() {
    const {
      account,
      user,
      items
    } = this.state;
    const { session } = this.props;
    let editButton;

    if (session.account && account.id === session.account.id ) {
      editButton = <Button onClick={this.handleEditClick}>Edit Profile</Button>;
    }

    let body = (
      <Row className='empty-message'>
        <Col>
          <h3>{`${ account.name } currently has no listed items.`}</h3>
        </Col>
      </Row>
    );

    if (items.length > 0) body = <ItemsViewer
                                    items={items}
                                    onItemSelect={this.selectedItem}
                                    onEditSelect={this.selectedEdit}
                                    onDeleteSelect={this.selectedDelete}
                                    owner={session.account && account.id === session.account.id}/>;
    return (
      <Row>
        { this.showModal() }
        <Col>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Row className='profile-header'>
                <Col md={{ span: 3 }}>
                  <Avatar className='profile-image' src={user.avatar ? user.avatar.url : null} onClick={this.handleAvatarClick}>
                    { account.name.substring(0,2).toUpperCase() }
                  </Avatar>
                </Col>
                <Col md={{ span: 6, offset: 1 }}>
                  <h1 className='account-name'>{ account.name }</h1>
                  { editButton }
                </Col>
              </Row>
              <Row className='item-search'>
                <Col>
                  <Search placeholder='Search for items' onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
                </Col>
              </Row>
            </Col>
          </Row>
          { body }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  onError: alert.error,
  onSuccess: alert.success,
  beginLoading: loading.begin,
  endLoading: loading.end,
  getProfile: profile.Get,
  updateProfile: profile.Update,
  getItem: item.Get,
  init: app.init
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
