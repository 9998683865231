import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  ListGroup
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './search.css';

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: props.results,
      value: props.searchValue ? props.searchValue : ''
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.parseResults = this.parseResults.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(e) {
    e.preventDefault();
    const { value } = e.target;
    this.setState({value});
    return this.props.onTextChange(value);
  }

  onSelect(e, i) {
    e.preventDefault();
    this.setState({value: ''});
    return this.props.onSelect(i);
  }

  onSubmit(e) {
    e.preventDefault();
    return this.props.onSubmit();
  }

  parseResults() {
    return (
      <ListGroup className="search-results">
        { this.props.results.map((r, i) => <ListGroup.Item key={i} onClick={e => this.onSelect(e, i)}>{r}</ListGroup.Item>)}
      </ListGroup>
    );
  }

  render() {
    const { value } = this.state;
    const results = this.props.results && this.props.results.length > 0 ? this.parseResults() : null;
    const placeholder = this.props.placeholder !== null ? this.props.placeholder : "Search";

    return (
      <div>
        <Form>
          <Form.Row className="align-items-center">
            <Col xs={10} className="my-1">
              <Form.Control type="text" placeholder={ placeholder } value={value} className="mr-sm-2" onChange={this.onTextChange}/>
            </Col>
            <Col xs={2} className="my-1">
              <Button onClick={this.onSubmit} type="submit">Search</Button>
            </Col>
          </Form.Row>
        </Form>
        { results }
      </div>
    );
  }
}

SearchComponent.propTypes = {
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.string),
  onTextChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSelect: PropTypes.func,
};

export default SearchComponent;
