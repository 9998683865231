
class Canvas {
  constructor(size = {width: 0, height: 0}) {
    const c = document.createElement('canvas');
    const {
      width,
      height
    } = size;

    if (width && height) {
      c.width = width;
      c.height = height;
    }

    return c;
  }
}

export default Canvas;
