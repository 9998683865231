import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { QueryParser } from '../../query';
import {
  loading,
  friend,
  invitation
} from '../../actions';
import {
  AddFriend,
  Search,
  Modal
} from '../../components';
import {
  Col,
  Row,
  Tabs,
  Tab
} from 'react-bootstrap';
import './FriendsPage.css';
import FriendsDashboard from './friend-dashboard';
import PendingFriendRequests from './pending-friend-requests';
import PendingInvitations from './pending-invitations';

class FriendsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'friends',
      search: null,
      searchQuery: null
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showInviteModal = this.showInviteModal.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
  }

  componentDidMount() {
    const { key } = QueryParser(this.props);

    if (key && key.length > 0) {
      return this.setState({ key });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      const { key } = QueryParser(this.props);

      if (key && key.length > 0) {
        return this.setState({ key });
      }
    }
  }

  onSearchFieldChange(searchQuery) {
    this.setState({ searchQuery });
  }

  onSearchSubmit() {
    const { searchQuery } = this.state;
    this.setState({ search: searchQuery });
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  showInviteModal() {
    return this.setState({
      modal: {
        title: 'Invite New Friend',
        content: <AddFriend onSubmit={this.sendInvite} title='Invite Friend' />
      }
    });
  }

  sendInvite(invite) {
    const { sendInvitation } = this.props;

    sendInvitation(invite);
    return this.setState({ modal: undefined });
  }

  render() {
    const {
      key,
      search
    } = this.state;
    let placeholder;

    if (key === "invitations") placeholder = 'Search Invitations';
    else if (key === "requests") placeholder = 'Search Requests';
    else placeholder = 'Search Friends';

    return (
      <Row>
        { this.showModal() }
        <br />
        <Col>
          <Row className='search-bar'>
            <Col md={{ span: 6, offset: 3 }}>
              <Search placeholder={placeholder} onTextChange={this.onSearchFieldChange} onSubmit={this.onSearchSubmit} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                activeKey={key}
                onSelect={(k) => this.setState({ key: k, searchQuery: null, search: null })}>
                <Tab eventKey="friends" title="Friends">
                  <FriendsDashboard search={key === 'friends' ? search : null} createInvitation={this.showInviteModal} />
                </Tab>
                <Tab eventKey="invitations" title="Pending Invitations" search={key === 'invitations' ? search : null}>
                  <PendingInvitations />
                </Tab>
                <Tab eventKey="requests" title="Pending Friend Requests" search={key === 'requests' ? search : null}>
                  <PendingFriendRequests createInvitation={this.showInviteModal} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getFriend: friend.Get,
  sendInvitation: invitation.Create
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FriendsPage));
