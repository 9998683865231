const GetUser = async (core, account, id) => {
  const { requests } = core;
  try {
    const {
      meta,
      data,
      paging
    } = await requests.user.get(account, id);

    if (Array.isArray(data)) {
      return Promise.resolve({
        meta,
        data: data.map(a => a.export()),
        paging
      });
    }
    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreateUser = async (core, account, user) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.user.create(new models.User(user), account);

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdateUser = async (core, user) => {
  const {
    requests,
    models
  } = core;

  try {
    const {
      meta,
      data
    } = await requests.user.update(new models.User(user));

    return Promise.resolve({
      meta,
      data: data.export()
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeleteUser = async (core, user) => {
  const {
    requests,
    models
  } = core;

  try {
    const { meta } = await requests.user.delete(new models.User(user));

    return Promise.resolve({ meta });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  GetUser,
  CreateUser,
  UpdateUser,
  DeleteUser
};
