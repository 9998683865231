import { invitationConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case invitationConstants.REQUESTING:
    case invitationConstants.RETRIEVED:
    case invitationConstants.CREATED:
    case invitationConstants.UPDATED:
    case invitationConstants.DELETED:
    case invitationConstants.SELECTED:
      return {...state, ...action};
    case invitationConstants.CLEAR:
      return {};
    default:
      return state;
  }
};
