import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading,
  booking
} from '../../../actions';
import { bookingConstants } from '../../../constants';
import {
  Addbooking,
  Modal
} from '../../../components';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import {
  TablePagination,
  TableContainer,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import './PendingBookings.css';

class PendingBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: {
        data: [],
        paging: {
          limit: 9,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };
    this.renderList = this.renderList.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.deleteBookings = this.deleteBookings.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.showModal = this.showModal.bind(this);
    this.selectedBooking = this.selectedBooking.bind(this);
  }

  componentDidMount() {
    return this.refreshList();
  }

  refreshList(search) {
    const { getBookings } = this.props;
    const { bookings } = this.state;
    const { paging } = bookings;
    const {
      limit,
      offset
    } = paging;
    const query = {
      limit,
      offset
    };

    if (search) query.search = search;
    return getBookings(query);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      booking,
      endLoading,
      search
    } = this.props;

    if (prevProps.search !== search) {
      return this.refreshList(search);
    }

    if (prevProps.booking !== booking) {
      if (booking.type === bookingConstants.RETRIEVED) {
        const { bookings } = booking;

        if (bookings) {
          this.setState({ bookings });
          return endLoading();
        }
      }

      if (booking.type === bookingConstants.DELETED) {
        return this.refreshList();
      }
    }
  }

  onRowsPerPageChange(row) {
    this.props.getBookings({
      offset: 0,
      limit: row
    });
  }

  onPageChange(page) {
    const { bookings } = this.state;
    const { paging } = bookings;
    const { limit } = paging;

    this.props.getBookings({
      offset: limit * page,
      limit
    });
  }

  deleteBookings(requests) {
    requests.forEach((booking, i) => this.props.deleteBookings(booking));
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  selectedBooking(e, booking) {
    e.preventDefault();
    const { history } = this.props;

    history.push(`/booking/${booking.id}`);
  }

  renderList() {
    const { bookings } = this.state;

    if (bookings && bookings.data.length > 0) {
      let {
        data,
        paging
      } = bookings;

      if (paging) {
        return (
          <Paper>
            <TableContainer>
              <List>
              {
                data = data.map((d, i) => {
                  const { renter } = d;
                  const {
                    account,
                    user
                  } = renter;
                  const { created_at } = d.record_history;
                  const diffInDays = Math.ceil((new Date().getTime() - new Date(created_at).getTime()) / (1000 * 3600 * 24));
                  const daysAgo = diffInDays > 0 ? `Connected ${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago` : '';

                  return (
                    <ListItem onClick={e => this.selectedBooking(e, d)}>
                      <ListItemAvatar onClick={e => this.navigateToProfile(e, account.name)}>
                        <Avatar src={user.avatar ? user.avatar.url : null}>{ account.name.substring(0,2).toUpperCase() }</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className='friend-text'
                        primary={account.name}
                        secondary={daysAgo}
                      />
                      <ListItemSecondaryAction>
                        <Button variant="danger">Remove</Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
              }
            </List>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
          />
        </Paper>
       )
      }
    }
    return (
      <Row className='empty-message'>
        <Col>
          <h3>You currently have no pending requests.</h3>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        { this.showModal() }
        <Col>
          { this.renderList() }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getBookings: booking.Get,
  updateBooking: booking.Update,
  deleteBooking: booking.Delete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingBookings));
