import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone'
import {
  DataEngine,
  ImageEngine
} from '../../utils';
import CircularProgress from '../circular-progress';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import './DropZone.css';

class DropZone extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  async onDrop(newFiles) {
    let {
      files,
      multiple,
      onFilesDropped,
      onError
    } = this.props;
    let filtered = [];

    try {
      for (let f of newFiles) {
        const img = await ImageEngine.resizeImage(f, f.type, f.name, {
          width: 50,
          height: 50
        });

        filtered.push({
          file: f,
          progress: 0,
          presentation: img
        });
      };

      if (multiple) {
        return onFilesDropped(files.concat(filtered));
      }
      return onFilesDropped(filtered);
    } catch (e) {
      return onError(e.message);
    }
  }

  render() {
    const { files } = this.props;
    const dropMessage = (
      <div>
        <h5>Click me or drag a file</h5>
        <CameraAltIcon style={{ fontSize: 60 }} color="primary" />
        <AddPhotoAlternateIcon style={{ fontSize: 60 }} color="primary" />
      </div>
    )
    return (
      <div className='drop-zone-container'>
        <Dropzone
        onDrop={this.onDrop}
        multiple={this.props.multiple}
        accept={this.props.accept}
        >
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()} className='drop-zone'>
              <input {...getInputProps()} />
              { isDragActive ? <h5>Drop it like it's hot!</h5> : dropMessage }

              <ul className="list-group mt-2 dropzone-list">
                { files.map((f, i) => (
                  <li key={i} className="dropzone-item">
                    <img
                      className='dropzone-item-img'
                      alt="upload"
                      src={f.presentation}
                    />
                    <p className='dropzone-item-name'>{f.file.name}</p>
                    <CircularProgress className='dropzone-progress' value={f.progress} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

DropZone.defaultProps = {
  multiple: false,
  files: []
};

DropZone.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.object,
      progress: PropTypes.number,
      presentation: PropTypes.string
    })
  ),
  onFilesDropped: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DropZone);
