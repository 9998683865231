import React from 'react';
import {
  withRouter,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import {
  AccessManagerPage,
  BookingPage,
  BillingPage,
  BookingsPage,
  FriendsPage,
  ItemPage,
  ItemsPage,
  SearchPage,
  LoginPage,
  ProfilePage,
  RecoveryPage,
  ResetPage,
  RegistrationPage,
  SettingsPage
} from '../pages';

import PrivateRouter from './private-router';

const Routes = props => {
  return (
    <Switch>
      <Route exact path='/'>
        <LoginPage />
      </Route>
      <Route path="/recovery">
        <RecoveryPage />
      </Route>
      <Route path="/invitation">
        <RegistrationPage />
      </Route>
      <Route path="/register">
        <RegistrationPage />
      </Route>
      <Route path="/reset">
        <ResetPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/account/:name">
        <ProfilePage />
      </Route>
      <Route path="/booking/:id">
        <BookingPage />
      </Route>
      <Route path="/item/:id">
        <ItemPage />
      </Route>
      <PrivateRouter path="/access-manager">
        <AccessManagerPage />
      </PrivateRouter>
      <PrivateRouter path="/search">
        <SearchPage />
      </PrivateRouter>
      <PrivateRouter path="/friends">
        <FriendsPage />
      </PrivateRouter>
      <PrivateRouter path="/items">
        <ItemsPage />
      </PrivateRouter>
      <PrivateRouter path="/settings">
        <SettingsPage />
      </PrivateRouter>
      <PrivateRouter path="/billing">
        <BillingPage />
      </PrivateRouter>
      <PrivateRouter path="/bookings">
        <BookingsPage />
      </PrivateRouter>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default withRouter(Routes);
