import {
  CreateAccount,
  DeleteAccount
} from './account.services';
import {
  CreateUser,
  DeleteUser
} from './user.services';
import { ActivationEmail } from './activation.services';

const sendValidationNotification = (core, account, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ActivationEmail(core, user);

      return resolve(`A validation email has been sent to ${user.email}`);
    } catch (e) {
      return RollbackRegistration(core, account, user, e).catch(reject);
    }
  });
}

const registerUser = (core, account, email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await CreateUser(core, account.id, {
        email,
        password
      });
      const message = await sendValidationNotification(core, account, data);

      return resolve(
        {
          message,
          user: data,
          account
        });
    } catch (e) {
      return RollbackRegistration(core, account, null, e).catch(reject);
    }
  });
}

const validatePassword = (password) => {
  const special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const lowercase = /[a-z]/;
  const uppercase = /[A-Z]/;
  const numbers = /[0-9]/;

  return (password.length >= 8
    && special.test(password)
    && lowercase.test(password)
    && uppercase.test(password)
    && numbers.test(password));
}

const RollbackRegistration = (core, account, user, err) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (user) await DeleteUser(core, user);
      await DeleteAccount(core, account);

      return reject(err);
    } catch (e) {
      return reject(e);
    }
  });
}

const Register = (core, name, email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (validatePassword(password)) {
        const { data } = await CreateAccount(core, { name });
        const res = await registerUser(core, data, email, password);

        return resolve(res.data);
      }
      return reject(new Error('Password must be minimum 8 character length with special, uppercase, lowercase and numeric values.'));
    } catch (e) {
      return reject(e);
    }
  });
}

export {
  Register,
  RollbackRegistration
}
