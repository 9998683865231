import config from '../config';
const { Partizlice } = window;

const App = (state) => {
  return new Promise(async (resolve, reject) => {
    if (state && state.app && state.app.app) {
      return resolve(state.app.app);
    }
    try {
      const app = await Partizlice.app({
        host: `${config.host}/v${config.apiVersion}`,
        id: config.appId
      });

      return resolve(app);
    } catch (e) {
      return reject(e);
    }
  });
};

export {
  App
};
