import React, { useState } from 'react';
import { connect } from 'react-redux';
import Map from '../map';
import Search from '../search';
import { Location } from '../../services';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import './Locator.css';

const Locator = props => {
  const {
    coords,
    setLocation,
    onError
  } = props;
  const [coordinates, setCoordinates] = useState(coords);
  const [address, setAddress] = useState('');
  const [results, setResults] = useState([]);
  const [markers, setMarkers] = useState([]);

  const queryLocation = async query => {
    try {
      const { results } = await Location.query(query);
      setResults(results);
      setAddress(query);
    } catch (e) {
      onError(e);
    }
  }

  const onSelect = i => {
    const {
      formatted,
      geometry
    } = results[i];
    const coords = [geometry.lat, geometry.lng];
    setResults([]);
    setAddress(formatted);
    setCoordinates(coords);
    setMarkers([{ position: coords }]);
  }

  const onMapClick = p => {
    const { latlng } = p;
    const coords = [latlng.lat, latlng.lng];

    setCoordinates(coords);
    setMarkers([{ position: coords }]);
  }

  return (
    <div>
      <Row>
        <Col>
          <Search
            placeholder='Address'
            searchValue={address}
            results={results.map(r => r.formatted)}
            onTextChange={queryLocation}
            onSubmit={e => queryLocation(address)}
            onSelect={onSelect}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Map coordinates={coordinates} markers={markers} onClick={onMapClick} />
        </Col>
      </Row>
      <Button onClick={e => setLocation(coordinates)}>Set Location</Button>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Locator);
