import { profileConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case profileConstants.REQUESTING:
    case profileConstants.RETRIEVED:
    case profileConstants.SELECTED:
    case profileConstants.CREATED:
    case profileConstants.UPDATED:
    case profileConstants.DELETED:
      return {...state, ...action};
    case profileConstants.CLEAR:
      return {};
    default:
      return state;
  }
};
