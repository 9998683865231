import { reverseString } from '../utils';

const UploadLink = (app, name, type) => {
  const { requests } = app;

  return requests.data.link(name, type);
}

const UploadFile = (app, url, file, fields, onProgress, onError, onComplete) => {
  const { requests } = app;

  return requests.data.upload(url, file, fields, null, onProgress, onError, onComplete);
}

const DeleteFile = (app, file) => {
  const { requests } = app;

  return requests.data.delete(file.key);
}

const DeleteBatch = (app, files) => {
  const { requests } = app;

  return requests.data.delete(files);
}

const FileUploader = (app, fileKey, file, cb) => {
  return new Promise(async (resolve, reject) => {
    const {
      name,
      type
    } = file;

    if (app) {
      const progress = (e) => {
        if (cb) cb(Math.round(e.loaded / e.total * 100));
      }

      let fileName = fileKey !== undefined ? `${fileKey}_${name.split('.')[0]}` : name;

      fileName = reverseString(fileName);
      fileName = fileName.slice(fileName.indexOf(".") + 1);
      fileName = reverseString(fileName);
      try {
        const {
          url,
          fields
        } = await UploadLink(app, fileName, type);
        const upload = await UploadFile(app, url, file, fields, progress);

        upload.type = type;
        return resolve(upload);
      } catch (e) {
        return reject(e.message);
      }
    }
    return reject('app is required');
  });
}

export {
  FileUploader,
  UploadLink,
  UploadFile,
  DeleteFile,
  DeleteBatch
}
