import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Form,
  Row,
  Col
} from 'react-bootstrap';
import { clientConstants } from '../../constants';
import {
  alert,
  loading,
  client
} from '../../actions';
import { copyStringToClipboard } from '../../utils';
import './AccessManager.css';

class AccessManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: []
    }
    this.handleCopy = this.handleCopy.bind(this);
    this.renderClients = this.renderClients.bind(this);
    this.onEnabledChange = this.onEnabledChange.bind(this);
  }

  componentDidMount() {
    const { client } = this.props;

    if (!client.clients) {
      this.props.get();
      this.props.beginLoading('Retrieving Client Credentials');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { client } = this.props;
    const prevClient = prevProps.client;

    if (client !== prevClient) {
      const { type } = client;

      if (type !== clientConstants.REQUESTING) {
        this.props.endLoad();

        const { clients } = client;

        if (type === clientConstants.GET_SUCCESS
          || type === clientConstants.UPDATE_SUCCESS
          || type === clientConstants.DELETE_SUCCESS) {
          return this.setState({ clients });
        }
      }
    }
  }

  handleCopy(e, string) {
    e.preventDefault();
    copyStringToClipboard(string);
    this.props.onSuccess('Copied to clipboard.');
  }

  onEnabledChange(e, i) {
    e.preventDefault();
    let { clients } = this.state;
    const c = clients[i];
    c.enabled = !c.enabled;

    return this.props.update(c);
  }

  renderClients() {
    const { clients } = this.state;
    return clients.map((c, i) => {
      const switchId = `switch-${i}`;
      return (
        <Row key={i}>
          <Col>
            <Card className='client-card'>
              <Card.Header>
              <p className='permission-level'>Permission Level: {c.accessLevel}</p>
              {c.name}
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="clientId">
                      <Form.Label>Client ID</Form.Label>
                      <Form.Control
                        className='client-field'
                        type="text"
                        value={c.id}
                        onClick={e => this.handleCopy(e, c.id)}
                        readonly
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="clientSecret">
                      <Form.Label>Client Secret</Form.Label>
                      <Form.Control
                        className='client-field'
                        type="text"
                        value={c.secret}
                        onClick={e => this.handleCopy(e, c.secret)}
                        readonly
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <Form.Check
                      type="switch"
                      id={switchId}
                      checked={c.enabled}
                      label={c.enabled ? 'Enabled' : 'Disabled'}
                      onChange={e => this.onEnabledChange(e, i)}
                    />
                  </ Col>
                </ Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      );
    });
  }

  render() {
    const { session } = this.props;
    return (
      <div>
        <h1>Access Manager</h1>
        <Row>
          <Col>
            <h5 className='account-id'>Account ID: { session.account.id }</h5>
          </Col>
        </Row>
        { this.renderClients() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  get: client.Get,
  create: client.Create,
  update: client.Update,
  delete: client.Delete,
  onSuccess: alert.success,
  onError: alert.error,
  onClear: alert.clear,
  beginLoading: loading.begin,
  endLoad: loading.end
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManager);
