import Http from './xhr';
import Canvas from './canvas';
import DataEngine from './data-engine';
import ImageEngine from './image-engine';
import Geolocator from './geolocator';

const detectMobileOrTablet = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const copyStringToClipboard = (str) => {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

const reverseString = (str) => {
  let splitString = str.split("");
  let reverseArray = splitString.reverse();
  let joinArray = reverseArray.join("");

  return joinArray;
}

export {
  Http,
  Canvas,
  DataEngine,
  Geolocator,
  ImageEngine,
  copyStringToClipboard,
  detectMobileOrTablet,
  reverseString
};
