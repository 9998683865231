import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  app,
  loading,
  item
} from '../../actions';
import { Modal } from '../../components';
import {
  appConstants,
  bookingConstants,
  itemConstants
} from '../../constants';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import {
  Avatar,
  GridList,
  GridListTile
} from '@material-ui/core';
import BookItem from './book-item';
import './ItemPage.css';

class ItemPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {}
    };

    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showBookingModal = this.showBookingModal.bind(this);
    this.renderImages = this.renderImages.bind(this);
  }

  componentDidMount() {
    const {
      beginLoading,
      location,
      app,
      getItem,
      init
    } = this.props;
    const { pathname } = location;
    const id = pathname.split('/item/')[1];

    if (id) {
      beginLoading('Retrieving Item');
      if (app && app.app) {
        return getItem(null, id);
      }
      return init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      booking,
      item,
      endLoading,
      location,
      app,
      getItem
    } = this.props;
    const { pathname } = location;
    const id = pathname.split('/item/')[1];

    if (prevProps.app !== app
      && app.type === appConstants.INITIALIZED) {
        if (!id) {
          return endLoading();
        }
        return getItem(null, id);
    }

    if (prevProps.item !== item
      && item.type === itemConstants.RETRIEVED) {
      this.setState({ item: item.item });
      return endLoading();
    }

    if (prevProps.booking !== booking
      && booking.type === bookingConstants.CREATED) {
      endLoading();
      return this.setState({ modal: undefined });
    }
  }

  onSearchFieldChange(searchQuery) {
    this.setState({ searchQuery });
  }

  onSearchSubmit() {
    const { searchQuery } = this.state;
    if (searchQuery && searchQuery.length > 0) {
      this.setState({ search: searchQuery });
    }
  }

  showModal() {
    const { modal } = this.state;

    if (modal) {
      const {
        title,
        content
      } = modal;

      return (
        <Modal
        title={title}
        show={content !== undefined}
        onHide={e => this.setState({ modal: undefined })}>
          { content }
        </Modal>
      );
    }
    return null;
  }

  showBookingModal(e) {
    e.preventDefault();
    const { item } = this.state;

    return this.setState({
      modal: {
        title: `Request to borrow ${item.name}`,
        content: <BookItem onDismiss={ () => this.setState({ modal: undefined }) } />
      }
    });
  }

  renderImages() {
    const { item } = this.state;
    const { media } = item;

    if (media) {
      if (media.length > 1) {
        return (
          <GridList className='item-grid-list' cellHeight={160} cols={3}>
            {
              media.map((m, i) => (
                <GridListTile key={i} cols={(i + 1) % 2 === 0 ? 2 : 1}>
                  <img src={m.url} alt={m.id} />
                </GridListTile>
              ))
            }
          </GridList>
        );
      }
      const image = media[0];

      return <img className='item-main-image' src={image.url} alt={image.id} />
    }
    return null;
  }

  render() {
    const { item } = this.state;

    if (item.name) {
      const {
        account,
        user
      } = item.owner;
      return (
        <Row>
          { this.showModal() }
          <Col>
            <Row className='item-header'>
              <Col md={{ span: 8, offset: 2 }}>
                <Row>
                  <Col>
                    <h1 className='item-title'>{item.name ? item.name : 'No Item Found'}</h1>
                  </Col>
                  <Col>
                    <Button className='book-button' onClick={this.showBookingModal}>Borrow</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                { this.renderImages() }
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Row>
                  <Col sm={{ span: 2}}>
                    <Avatar className='item-profile-image' src={user.avatar ? user.avatar.url : null} onClick={this.handleAvatarClick}>
                      { account.name.substring(0,2).toUpperCase() }
                    </Avatar>
                  </Col>
                  <Col sm={{ offset: 2}}>
                    <h2 className='account-name'>{ account.name }</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                { item.description }
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return <h1>No Item Found</h1>;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  init: app.init,
  beginLoading: loading.begin,
  endLoading: loading.end,
  getItem: item.Get
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemPage));
