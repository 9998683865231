const DefaultAccount = async (core) => {
  if (core) {
    try {
      const { requests } = core;
      const {
        meta,
        data
      } = await requests.default.account();

      return Promise.resolve({
        meta,
        data: data.export()
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject('No core found.');
}

const DefaultRequester = async (core) => {
  if (core) {
    try {
      const { requests } = core;
      const res = await requests.default.requester();
      const {
        meta,
        data
      } = res;

      return Promise.resolve({
        meta,
        data: data.export()
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject('No core found.');
}

export {
  DefaultAccount,
  DefaultRequester
};
