const authenticated = [
  // {
  //   to: 'settings',
  //   name: 'Settings'
  // },
  {
    path: '/account',
    title: 'Profile'
  },
  {
    path: '/logout',
    title: 'Sign-out'
  }
];

const unauthenticated = [
  {
    path: '/login',
    title: 'Sign-in'
  },
  {
    path: '/register',
    title: 'Register'
  }
];

const sidedBarMenu = [
  {
    title: 'Search',
    color: '#5f99cf',
    path: '/search'
  },
  {
    title: 'Items',
    color: '#4dad9d',
    path: '/items',
  },
  {
    title: 'Requests',
    color: '#d7734b',
    path: '/bookings',
  },
  {
    title: 'Friends',
    color: '#7869aa',
    path: '/friends',
  }
];

// '#cbe772',
// '#6098cd',
// '#8667e7',
// '#ad556f',
// '#dbae65'

export {
  authenticated,
  unauthenticated,
  sidedBarMenu
}
