import React from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  Route,
  Redirect
} from "react-router-dom";

const PrivateRouter = props => {
  const { core } = props.session;

  return (
    <Route
      {...props.rest}
      render={({ location }) =>
        core ? ( props.children ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRouter));
