import {
  invitationConstants,
  friendConstants
} from '../constants';
import {
  SessionCore,
  SessionAccount,
  GetInvitation,
  CreateInvitation,
  UpdateInvitation,
  DeleteInvitation,
  DeleteInvitationBatch,
  CreateFriend
} from '../services';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

const requesting = () => {
  return { type: invitationConstants.REQUESTING }
}

const retrieved = (invitation, invitations, pending) => {
  return {
    type: invitationConstants.RETRIEVED,
    invitation,
    invitations,
    pending
  }
}

const created = (invitation) => {
  return {
    type: invitationConstants.CREATED,
    invitation
  }
}

const updated = (invitation) => {
  return {
    type: invitationConstants.UPDATED,
    invitation
  }
}

const deleted = () => {
  return {
    type: invitationConstants.DELETED
  }
}

const friendCreated = (friend) => {
  return {
    type: friendConstants.CREATED,
    friend
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: invitationConstants.CLEARED
    });
  };
}

const Get = (id, query) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Retrieving Invitations'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data,
        paging
      } = await GetInvitation(core, account.id, id, query);
      let {
        invitation,
        invitations,
        pending
      } = state.invitation;

      if (Array.isArray(data)){
        if (query.pending) pending = { data, paging };
        else invitations = { data, paging };
      } else invitation = data;
      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(retrieved(invitation, invitations, pending));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Create = (invitation) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Sending Invitation'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateInvitation(core, account, invitation);

      if (meta.message) dispatch(alert.success(meta.message));
      dispatch(loading.end());
      return dispatch(created(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Update = (invitation, query) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Updating Invitation'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const {
        meta,
        data
      } = await UpdateInvitation(core, invitation, query);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(updated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Delete = (invitation) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Deleting Invitation'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const { meta } = await DeleteInvitation(core, invitation, account.id);

      if (meta.message) dispatch(alert.success(meta.message));
      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const DeleteBatch = (invitations) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Deleting Invitations'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const { meta } = await DeleteInvitationBatch(core, invitations, account);

      if (meta && meta.message) dispatch(alert.success(meta.message));
      return dispatch(deleted());
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

const Accept = (invitation) => {
  return async (dispatch, getState) => {
    dispatch(loading.begin('Accepting Invitation'));
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      const account = await SessionAccount(state);
      const {
        meta,
        data
      } = await CreateFriend(core, account, { account: invitation.inviter.account.id });
      await DeleteInvitation(core, invitation, account.id);
      if (meta && meta.message) dispatch(alert.success(meta.message));
      dispatch(loading.end());
      return dispatch(friendCreated(data));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const invitation = {
  Get,
  Create,
  Update,
  Delete,
  DeleteBatch,
  Clear,
  Accept
};
