import {
  ActivateAccount,
  App
} from '../services';

import { alert } from './alert.actions';

/**
 * METHODS
 */
const code = (code) => {
  return async (dispatch, getState) => {
    try {
      const app = await App(getState());

      if (!app) {
        return dispatch(alert.error('App is not loaded'));
      }

      const res = await ActivateAccount(app, code);
      return dispatch(alert.success(res.message));
    } catch (e) {
      return dispatch(alert.error(e.message));
    }
  }
}

export const activation = {
  code
};
