import { userConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case userConstants.REQUESTING:
    case userConstants.RETRIEVED:
    case userConstants.CREATED:
    case userConstants.UPDATED:
    case userConstants.DELETED:
      return {...state, ...action};
    case userConstants.CLEARED:
      return {};
    default:
      return state;
  }
};
