import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  alert,
  item,
  loading
} from '../../actions';
import { itemConstants } from '../../constants';
import DeleteForm from '../delete-form';
import './DeleteItemForm.css';

class DeleteItemForm extends Component {
  constructor(props) {
    super(props);
    this.executeDelete = this.executeDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      endLoading,
      onSuccess,
      onDismiss
    } = this.props;

    if (prevProps.item !== item
      && item.type === itemConstants.DELETED) {
      onSuccess('Item Deleted');
      onDismiss();
      return endLoading();
    }
  }

  executeDelete(item) {
    const {
      beginLoading,
      deleteItem
    } = this.props;

    beginLoading('Deleting Item');
    deleteItem(item);
    return this.setState({processing: true});
  }

  render() {
    const {
      itemToDelete,
      onError
    } = this.props;

    return <DeleteForm data={itemToDelete} confirmationString={itemToDelete.name} onDelete={this.executeDelete} onError={onError} />
  }
}

DeleteItemForm.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  itemToDelete: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  deleteItem: item.Delete,
  onSuccess: alert.success,
  onError: alert.error
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteItemForm);
