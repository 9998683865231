export const invitationConstants = {
  REQUESTING: 'INVITATION_REQUEST_BEGIN',
  SUCCESS: 'SESSION_INVITATION_SUCCESS',
  RETRIEVED: 'INVITATION_GET_SUCCESS',
  CREATED: 'INVITATION_CREATE_SUCCESS',
  UPDATED: 'INVITATION_UPDATE_SUCCESS',
  DELETED: 'INVITATION_DELETE_SUCCESS',
  CLEAR: 'INVITATION_REQUEST_CLEARED',
  FAILED: 'INVITATION_REQUEST_FAILURE'
};
