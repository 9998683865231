import React from 'react';
import { Steps, Button, message } from 'antd';
import PropTypes from 'prop-types';
import './Steps.css';
const { Step } = Steps;

const StepsComponent = props => {
  const {
    current,
    steps
  } = props;
  return (
    <div>
      <Steps current={current}>
        {steps.map(s => (
          <Step key={s.title} title={s.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={props.onNextClicked}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={props.onPrevClicked}>
            Previous
          </Button>
        )}
      </div>
    </div>
  )
};

StepsComponent.propTypes = {
  current: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    content: PropTypes.node.isRequired,
  }),
  onPrevClicked: PropTypes.func,
  onNextClicked: PropTypes.func
}

export default StepsComponent;
