import { clientConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case clientConstants.REQUESTING:
    case clientConstants.RETRIEVED:
    case clientConstants.CREATED:
    case clientConstants.UPDATED:
    case clientConstants.DELETED:
      return {...state, ...action};
    default:
      return state;
  }
};
