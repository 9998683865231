import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading,
  invitation
} from '../../../actions';
import {
  invitationConstants,
  friendConstants
} from '../../../constants';
import {
  TablePagination,
  TableContainer,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import './InvitationsFriends.css';

class InvitationsFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitations: {
        data: [],
        paging: {
          limit: 5,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };
    this.renderList = this.renderList.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.acceptInvite = this.acceptInvite.bind(this);
    this.declineInvite = this.declineInvite.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;

    this.refreshList(search);
  }

  refreshList(search) {
    const { getInvitations } = this.props;
    const { invitations } = this.state;
    const { paging } = invitations;
    const {
      limit,
      offset
    } = paging;
    const query = {
      verbose: true,
      limit,
      offset
    };

    if (search) query.search = search;
    return getInvitations(null, query);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      invitation,
      friend,
      endLoading,
      search
    } = this.props;

    if (prevProps.search !== search) {
      return this.refreshList(search);
    }

    if (prevProps.invitation !== invitation) {
      if(invitation.type === invitationConstants.RETRIEVED) {
        const { invitations } = invitation;

        if (invitations) this.setState({ invitations });
        return endLoading();
      }

      if (friend.type === friendConstants.CREATED
        || invitation.type === invitationConstants.CREATED
        || invitation.type === invitationConstants.UPDATED
        || invitation.type === invitationConstants.DELETED) {
        return this.refreshList();
      }
    }
  }

  onRowsPerPageChange(row) {
    this.props.getInvitations(null, {
      verbose: true,
      offset: 0,
      limit: row
    });
  }

  onPageChange(page) {
    const { invitations } = this.state;
    const { paging } = invitations;
    const { limit } = paging;

    this.props.getInvitations(null, {
      verbose: true,
      offset: limit * page,
      limit
    });
  }

  acceptInvite(e, invite) {
    e.preventDefault();
    this.props.acceptInvitation(invite);
  }

  declineInvite(e, invite) {
    e.preventDefault();
    this.props.deleteInvitation(invite);
  }

  renderList() {
    const { invitations } = this.state;

    if (invitations && invitations.data.length > 0) {
      let {
        data,
        paging
      } = invitations;

      if (paging) {
        return (
          <Paper>
            <TableContainer>
              <List>
              {
                data = data.map((d, i) => {
                  const {
                    inviter,
                    message,
                    record_history
                  } = d;
                  const { created_at } = record_history;
                  const diffInDays = Math.ceil((new Date().getTime() - new Date(created_at).getTime()) / (1000 * 3600 * 24));
                  const daysAgo = diffInDays > 0 ? `Connected ${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago` : '';
                  const {
                    account,
                    user
                  } = inviter
                  return (
                    <ListItem>
                      <ListItemAvatar className='invite-avatar' onClick={e => this.navigateToProfile(e, account.name)}>
                        <Avatar className='invite-avatar-img' src={user.avatar ? user.avatar.url : null}>{ account.name.substring(0,2).toUpperCase() }</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className='invite-text'
                        primary={account.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                            { message }
                            </Typography>
                            <br />
                            { daysAgo }
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Row>
                          <Col>
                            <Button variant="primary" onClick={e => this.acceptInvite(e, d)}>Accept</Button>
                          </Col>
                          <Col>
                            <Button variant="danger" onClick={e => this.declineInvite(e, d)}>Decline</Button>
                          </Col>
                        </Row>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
              }
            </List>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
          />
        </Paper>
       )
      }
    }
    return (
      <Row className='empty-message'>
        <Col>
          <h3>You currently have no pending friend requests</h3>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        <Col>
          { this.renderList() }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getInvitations: invitation.Get,
  updateInvitation: invitation.Update,
  deleteInvitation: invitation.Delete,
  acceptInvitation: invitation.Accept
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationsFriends));
