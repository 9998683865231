import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  loading,
  invitation
} from '../../../actions';
import { invitationConstants } from '../../../constants';
import { DataTable } from '../../../components';
import { Avatar } from '@material-ui/core';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import './PendingFriends.css';

class PendingFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: {
        data: [],
        paging: {
          limit: 5,
          offset: 0,
          page: 0,
          total: 1,
          total_pages: 1
        }
      }
    };
    this.renderList = this.renderList.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.resendInvitation = this.resendInvitation.bind(this);
    this.deleteInvitations = this.deleteInvitations.bind(this);
    this.showInvitationModal = this.showInvitationModal.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;

    return this.refreshList(search);
  }

  refreshList(search) {
    const { getInvitations } = this.props;
    const { pending } = this.state;
    const { paging } = pending;
    const {
      limit,
      offset
    } = paging;
    const query = {
      pending: true,
      verbose: true,
      limit,
      offset
    };

    if (search) query.search = search;
    return getInvitations(null, query);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      invitation,
      endLoading,
      search
    } = this.props;

    if (prevProps.search !== search) {
      return this.refreshList(search);
    }

    if (prevProps.invitation !== invitation) {
      if (invitation.type === invitationConstants.RETRIEVED) {
        const { pending } = invitation;

        if (pending) this.setState({ pending });
        return endLoading();
      }

      if (invitation.type === invitationConstants.CREATED
        || invitation.type === invitationConstants.UPDATED
        || invitation.type === invitationConstants.DELETED) {
        return this.refreshList();
      }
    }
  }

  onRowsPerPageChange(row) {
    this.props.getInvitations(null, {
      pending: true,
      verbose: true,
      offset: 0,
      limit: row
    });
  }

  showInvitationModal(e) {
    e.preventDefault();

    this.props.createInvitation();
  }

  onPageChange(page) {
    const { pending } = this.state;
    const { paging } = pending;
    const { limit } = paging;

    this.props.getInvitations(null, {
      pending: true,
      verbose: true,
      offset: limit * page,
      limit
    });
  }

  resendInvitation(e, i) {
    e.preventDefault();
    this.props.updateInvitation(i, {resend: true});
  }

  deleteInvitations(invitations) {
    this.props.deleteInvitations(invitations);
  }

  renderList() {
    const { pending } = this.state;

    if (pending && pending.data.length > 0) {
      let {
        data,
        paging
      } = pending;
      const columns = [
        { field: 'avatar', label: '' },
        { field: 'name', label: 'Name' },
        { field: 'email', label: 'Email' },
        { field: 'phone', label: 'Phone' },
        { field: 'sent', label: 'Sent At' },
        { field: 'resend', label: 'Resend Invite' }
      ];
      if (paging) {
        data = data.map((d) => {
          const {
            record_history,
            invitee
          } = d
          const {
            updated_at,
            created_at
          } = record_history

          const sentTime = !updated_at ? new Date(created_at) : new Date(updated_at);
          const diffInDays = Math.ceil((new Date().getTime() - sentTime.getTime()) / (1000 * 3600 * 24));
          const daysAgo = diffInDays > 0 ? `(${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago)` : '';
          const payload = {
            sent: `${sentTime.toLocaleDateString("en-US")} ${daysAgo}`,
            resend: <Button onClick={e => this.resendInvitation(e, d)}>Resend</Button>
          };

          if (invitee && invitee.account) {
            const {
              account,
              user
            } = invitee;
            payload.avatar = <Avatar className='pending-avatar-img' src={user.avatar ? user.avatar.url : null}>{ account.name.substring(0,2).toUpperCase() }</Avatar>;
            payload.name = account.name;
          } else payload.name = 'New User Invitation';
          return Object.assign({}, d, payload);
        });
        return <DataTable
          title='Pending Invites You Sent'
          cells={columns}
          rows={!data ? [] : data}
          total={!paging.total ? 0 : paging.total}
          page={!paging.page ? 0 : paging.page}
          rowsPerPage={!paging.limit ? 0 : paging.limit}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onPageChange={this.onPageChange}
          onDeleteClicked={this.deleteInvitations} />;
      }
      return null;
    }
    return (
      <Row className='empty-message'>
        <Col>
          <h3>You currently have no pending invites</h3>
          <h4>Click below to create an invitation</h4>
          <Button onClick={this.showInvitationModal}>Send Invitation</Button>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        <Col>
          { this.renderList() }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  beginLoading: loading.begin,
  endLoading: loading.end,
  getInvitations: invitation.Get,
  updateInvitation: invitation.Update,
  deleteInvitation: invitation.Delete,
  deleteInvitations: invitation.DeleteBatch
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingFriends));
