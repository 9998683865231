import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  GridList,
  GridListTile
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import './SearchItemsViewer.css';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const SearchItemsViewer = props => {
  const classes = useStyles();
  const { items } = props;
  const [width, setWidth] = React.useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  window.addEventListener('resize', updateDimensions);

  const handleClick = (e, item) => {
    e.preventDefault();

    props.onItemClicked(item);
  }

  const handleAvatarClick = (e, account) => {
    e.preventDefault();

    props.onAvatarClicked(account);
  }

  const generateCard = (item, i) => {
    const {
      account,
      user
    } = item.owner;

    if (item.media.length > 0) {
      return (
        <GridListTile className='item-cell' key={i}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar className='account-image' src={user.avatar ? user.avatar.url : null} onClick={e => handleAvatarClick(e, account)}>
                  { account.name.substring(0,2).toUpperCase() }
                </Avatar>
              }
              title={item.name}
            />
            <CardMedia
              onClick={e => handleClick(e, item)}
              className={classes.media}
              image={item.media[0].url}
              title={item.name}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                { item.description }
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions>
          </Card>
        </GridListTile>
      );
    }
  }
  let cellHeight = 430;
  let cellCols = 3;
  if (width <= 930) cellHeight = 350;
  if (width <= 800) {
    cellCols = 2;
    cellHeight = 400;
  }
  if (width <= 650) {
    cellHeight = 250;
    cellHeight = 350;
  }
  if (width <= 520) {
    cellHeight = 450;
    cellCols = 1;
  }

  return (
    <div className={classes.root}>
      <GridList cellHeight={cellHeight} spacing={1} className={classes.gridList} cols={cellCols}>
        {items.map((item, i) => generateCard(item, i))}
      </GridList>
    </div>
  );
}

SearchItemsViewer.defaultProps = {
  items: []
};

SearchItemsViewer.propTypes = {
  onItemClicked: PropTypes.func.isRequired,
  onAvatarClicked: PropTypes.func.isRequired,
  items: PropTypes.array
};

export default SearchItemsViewer;
