import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QueryParser } from '../../query';
import { recoveryConstants } from '../../constants';
import {
  app,
  loading,
  recovery
} from '../../actions';
import {
  Button,
  Col,
  Form
} from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import './Reset.css';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRetypePasswordChange = this.onRetypePasswordChange.bind(this);
    this.buttonState = this.buttonState.bind(this);
    this.fieldsValidation = this.fieldsValidation.bind(this);
  }

  componentDidMount() {
    const {
      init,
      loadingBegin
    } = this.props;
    const { code } = QueryParser(this.props);

    if (code && code.length > 0) {
      loadingBegin('Loading Recovery Form');
      this.setState({ code });
      return init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      recovery,
      loadingEnd,
      history
    } = this.props;

    if (recovery.type === recoveryConstants.RECOVERY_SUCCESS) {
      loadingEnd();
      return history.push('/login');
    }
  }

  onPasswordChange(e) {
    e.preventDefault();

    this.setState({
      password: e.target.value
    });
  }

  onRetypePasswordChange(e) {
    e.preventDefault();

    this.setState({
      passwordRetype: e.target.value
    });
  }

  submit(e) {
    e.preventDefault();
    const {
      loadingBegin,
      reset
    } = this.props;
    const { password } = this.state;

    loadingBegin('Seding recovery email');
    return reset(password);
  }

  fieldsValidation() {
    const {
      password,
      passwordRetype
    } = this.state;

    return (
      !password
      || password.length < 1
      || !passwordRetype
      || passwordRetype.length < 1
    );
  }

  buttonState() {
    return {
      variant:"primary",
      type: "submit",
      onClick: this.submit,
      disabled: this.fieldsValidation()
    }
  }

  render() {
    return (
      <Col md={{ span: 6, offset: 3 }}>
        <h1>Password Recovery</h1>
        <Form>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={this.onPasswordChange} />
          </Form.Group>

          <Form.Group controlId="formRetypePassword">
            <Form.Label>Re-type Password</Form.Label>
            <Form.Control type="password" placeholder="Re-type Password" onChange={this.onRetypePasswordChange} />
          </Form.Group>

          <Button {...this.buttonState()}>
            Reset
          </Button>
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  reset: recovery.reset,
  loadingBegin: loading.begin,
  loadingEnd: loading.end,
  init: app.init,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reset));
