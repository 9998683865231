
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QueryParser } from '../../query';
import {
  loading,
  authenticate,
  app,
  alert
} from '../../actions';
import { appConstants } from '../../constants';
import { ActivateAccount } from '../../services';
import {
  Form,
  Button,
  Row,
  Card,
  Col
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.login = this.login.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.buttonState = this.buttonState.bind(this);
    this.fieldsValidation = this.fieldsValidation.bind(this);
    this.activateAccount = this.activateAccount.bind(this);
  }

  componentDidMount() {
    const {
      beginLoading,
      init,
      app
    } = this.props;
    const { activate } = QueryParser(this.props);
    let sso = localStorage.getItem('sso');

    if (sso || (activate && activate.length > 0)) {
      if (activate) this.setState({ activate });
      if (!app.app) {
        if (activate) beginLoading('Activating Account');
        else beginLoading('Authenticating...');

        return init();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      login,
      app
    } = this.props;
    const {
      email,
      password,
      activate
    } = this.state;
    const sso = localStorage.getItem('sso');

    if (prevProps.app !== app
      && app.type === appConstants.INITIALIZED) {
      if (activate) {
        return this.activateAccount(activate);
      }
      if (!sso && (email && password)) login(email, password);
    }
  }

  async activateAccount(code) {
    const {
      endLoading,
      onError,
      onSuccess,
      app
    } = this.props;

    try {
      await ActivateAccount(app.app, code);

      endLoading();
      return onSuccess('Account is now active! You may now login.');
    } catch (e) {
      endLoading();
      return onError(e.message);
    }
  }

  login(e) {
    e.preventDefault();
    const {
      beginLoading,
      init,
      app,
      login
    } = this.props
    const {
      email,
      password
    } = this.state;

    beginLoading('Authenticating');
    if (app && app.app) {
      return login(email, password);
    }
    return init();
  }

  onEmailChange(e) {
    e.preventDefault();

    this.setState({email: e.target.value});
  }

  onPasswordChange(e) {
    e.preventDefault();

    this.setState({password: e.target.value});
  }

  fieldsValidation() {
    const {
      email,
      password
    } = this.state;

    return (
      !email
      || email.length === 0
      || !password
      || password.length === 0);
  }

  buttonState() {
    return {
      variant:"primary",
      type: "submit",
      onClick: this.login,
      disabled: this.fieldsValidation()
    }
  }

  render() {
    return (
      <Row>
        <Col md={{ span: 4, offset: 1 }}>
          <Card className="auth-card">
            <Card.Body>
              <Card.Title>Sign-In</Card.Title>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="login-form-text">Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={this.onEmailChange} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label className="login-form-text">Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={this.onPasswordChange} />
                  <Form.Text id="passwordHelpBlock" muted>
                    Minimum 8 characters long.
                  </Form.Text>
                  <Link to="/recovery">Forgot Password</Link>
                </Form.Group>
                <Button {...this.buttonState()}>
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md={{ span: 7}}>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  init: app.init,
  login: authenticate.emailLogin,
  clear: authenticate.clear,
  beginLoading: loading.begin,
  endLoading: loading.end,
  onError: alert.error,
  onSuccess: alert.success
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
