import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon } from '@material-ui/core';
import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction
} from '@material-ui/lab';
import './AddButton.css';

const AddButton = props => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const onSelect = (e, item) => {
    e.preventDefault();
    setOpen(false);
    props.onItemSelect(item.name);
  };

  const generateItems = () => {
    return props.items.map((item, i) => {
      const icon = item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null;

      return (
        <SpeedDialAction
          key={i}
          icon={icon}
          tooltipTitle={item.name}
          onClick={e => onSelect(e, item)}
        />
      );
    })
  };

  return (
    <div className="add-icon-button">
      <SpeedDial
        ariaLabel="SpeedDial example"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleToggle}
        open={open}
        direction={'up'}
      >
        { generateItems() }
      </SpeedDial>
    </div>
  );
};

AddButton.propTypes = {
  onItemSelect: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.element
  })).isRequired
};

export default AddButton;
