import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it'
import MarkdownAttrs from 'markdown-it-attrs';
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';
import './MarkdownEditor.css';

class MarkdownEditor extends Component {
  constructor(props) {
    super(props);

    this.mdParser = new MarkdownIt({html: true});
    this.mdParser.use(MarkdownAttrs);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange({html, text}) {
    this.props.onContentUpdate(text);
  }

  render() {
    const { content } = this.props;
    return (
      <MdEditor
      value={content}
      style={{height:'600px'}}
      className='markdown-editor'
      renderHTML={(text) => this.mdParser.render(text)}
      onChange={this.handleEditorChange}
      />
    )
  }
}

MarkdownEditor.propTypes = {
  content: PropTypes.string.isRequired,
  onContentUpdate: PropTypes.func.isRequired
};

export default MarkdownEditor;
