import { appConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case appConstants.INITIALIZED:
      return {...state, ...action};
    default:
      return state;
  }
};
