import {
  authConstants,
  sessionConstants
} from '../constants';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';
import {
  App,
  DefaultRequester,
  Login,
  Refresh,
  Logout,
  SessionAccount,
  SessionUser,
  Location
} from '../services';

/**
 * STATES
 */
const sessionCoreCreated = (core) => {
  return {
    type: sessionConstants.CORE,
    core
  }
}

const sessionAccountCreated = (account) => {
  return {
    type: sessionConstants.ACCOUNT,
    account
  }
}

const sessionUserCreated = (user) => {
  return {
    type: sessionConstants.USER,
    user
  }
}

const sessionLocationRetrieved = (location) => {
  return {
    type: sessionConstants.LOCATION,
    location
  }
}

const clearSession = () => {
  return {
    type: sessionConstants.END
  }
}

const authBegin = dispatch => {
  return dispatch({ type: authConstants.BEGIN });
}

const authFailed = (dispatch, e) => {
  release(dispatch);
  if (e.status === 403) dispatch(alert.error('Invalid credentials'));
  else dispatch(alert.error(e.message));
  dispatch(loading.end());

  return dispatch({ type: authConstants.FAILED });
}

const getUserLocation = async (dispatch) =>{
  try {
    const location = await Location.getPosition();

    dispatch(sessionLocationRetrieved(location));
  } catch (e) {
    dispatch(alert.info(`${e.message} Turn on location access to improve search and booking experience.`));
  }
}

const authSuccess = async (dispatch, core) => {
  dispatch(sessionCoreCreated(core));
  try {
    const account = await SessionAccount(null, core);

    dispatch(sessionAccountCreated(account));
    const user = await SessionUser(null, core);

    dispatch(sessionUserCreated(user));

    getUserLocation(dispatch);
    return dispatch({ type: authConstants.SUCCESS });
  } catch (e) {
    return authFailed(dispatch, e);
  }
}

const clear = () => {
  return async dispatch => {
    return dispatch({ type: authConstants.CLEARED });
  };
}

const release = (dispatch) => {
  Logout();
  dispatch(clear);
  return dispatch(clearSession());
}

/**
 * METHODS
 */
const emailLogin = (email, password) => {
  return async (dispatch, getState) => {
    authBegin(dispatch);
    try {
      const app = await App(getState());

      if (!app) {
        return authFailed(dispatch, new Error('App is not loaded'));
      }
      const { data } = await DefaultRequester(app);

      if (data) {
        const core = await Login(data.id, email, password);

        return authSuccess(dispatch, core);
      }
      return authFailed(dispatch, new Error('No client found'));
    } catch (e) {
      return authFailed(dispatch, e);
    }
  }
}

const refreshLogin = (token) => {
  return async dispatch => {
    authBegin(dispatch);
    try {
      const core = await Refresh(token);

      return authSuccess(dispatch, core);
    } catch (e) {
      return authFailed(dispatch, e);
    }
  }
}

const logout = () => {
  return dispatch => {
    release(dispatch);
    return dispatch({ type: authConstants.LOGGEDOUT });
  }
}

export const authenticate = {
  clear,
  emailLogin,
  logout,
  refreshLogin
};
